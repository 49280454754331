/**
* form stylesheet 
*/

@import "vars";
@import "icons";

.input-hidden {
  opacity: 0;
  z-index: -999;
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
}

.input-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 .5em;

  &-title {
    text-transform: uppercase;
    flex: 0 0 100%;
    font-size: 0.85em;
    color: $headingColor;

    @media (max-width: 839px) {
      font-weight: 900;
      font-size: .75rem;
      margin-bottom: .5rem;
    }
  }

  &-input,
  &-select
  {
    width: 100%;
    font-size: 1em;
    padding: .75em;
    border-radius: $borderRadiusSmall;
    border: 1px solid $midLightGrey;
    background-color: $white;
    cursor: pointer;
  }

  &-input {
    &-wrapper {
      display: flex;
      align-items: center;
      align-content: center;
    }
  }

  &-select {
    display: block;
    height: 2.15em;

    &-wrapper {
      position: relative;
      width: 100%;

      &:after {
        position: absolute;
        right: .1em;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        background-color: $white;
        pointer-events: none;
        font-size: 1em;
        padding: .45em;
      }
    }
  }

  &-checkbox {
    &+.input-block-label {
      cursor: pointer;
      display: flex;
      align-items: center;
      align-content: center;
      position: relative;
      text-transform: capitalize;
      margin: .5em 0;
      padding-left: 1.7em;

      &:after {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        display: block;
        border: 1px solid $midGrey;
        border-radius: $borderRadiusTiny;
        width: 1.2em;
        height: 1.2em;
      }
    }

    &:checked+.input-block-label {
      @extend .icon-checkmark;

      &:before {
        color: $primaryColor;
        font-size: .5em;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(75%, -50%);
      }
    }
  }

  &-radio {
    @extend .input-hidden;

    + .input-block-label {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        position: relative;
        top: 2px;
      }

      &:before {
        content: '';
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 51%;
        display: inline-block;
        border: 1px solid $lightGrey;
        cursor: pointer;
        margin-right: .5rem;
      }
    }

    &:checked {
      + .input-block-label:after {
        content: '';
        height: .5rem;
        width: .5rem;
        background: $primaryColor;
        position: absolute;
        display: block;
        top: 0;
        left: .4375rem;
        bottom: 0;
        margin: auto;
        border-radius: 51%;
      }
    }
  }

  &-textarea {
    border-radius: $borderRadiusMid;
    min-height: 7.5rem;
    border: 1px solid $lightGrey;
    padding: 1rem;
    width: 100%;
    resize: none;
    margin-top: 1rem;
    font-size: .875rem;
    line-height: 1.5rem;
  }
}

.input-block-input-wrapper--place-search {
  > div {
    width: 100%;
  }
}

.custom-radio-icon {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 51%;
  display: inline-block;
  border: 1px solid $lightGrey;
  position: relative;
  cursor: pointer;

  &.checked {
    &:before {
      content: '';
      height: .5rem;
      width: .5rem;
      background: $primaryColor;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 51%;
    }
  }
}