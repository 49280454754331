/**
* _buttons stylesheet 
*/
@import "vars";

.btn {
  border: none;
  border-radius: $borderRadiusSmall;
  padding: .75em 2em;
  cursor: pointer;
  font-size: .85em;

  &:disabled {
    background-color: $veryLightGrey;
    color: $midGrey;
  }

  &-light-blue {
    @extend .btn;
    background: $primaryIceBlue;
    color: $midGrey;
    font-weight: 900;
  }


  &-empty {
    @extend .btn;
    background-color: transparent;
    border: none;
    color: $darkGrey;
    font-weight: 900;
    font-size: 1em;

    &-grey {
      @extend .btn;
      background-color: $white;
      border: 1px solid $lightGrey;
      color: $textColor;
    }

    &-red {
        @extend .btn;
        background-color: $white;
        border: 1px solid $red;
        color: $textColor;
      }

    &-cta {
      @extend .btn-empty;
      color: $primaryColor;
      font-weight: bold;
    }
  }

  &-cta {
    @extend .btn;
    border: none;
    background-color: $primaryColor;
    color: $white;

    &:disabled {
      background-color: $primaryIceBlue;
      color: $darkGrey;
      cursor: not-allowed;
    }
  }

  &-grey {
    @extend .btn;
    border: 1px solid $midGrey;
    color: $midGrey;
  }

  &-red {
    @extend .btn;
    border: 1px solid $midGrey;
    color: $red;
  }

  &-empty-blue {
    @extend .btn;
    color: $primaryColor;
    border: 1px solid $primaryColor;
  }

  &-white {
    @extend .btn;
    color: $textColor;
    background-color: $white;
  }

  &-grey,
  &-greyedBlue {
    background-color: $lightestGrey;
  }

  &-large {
    @extend .btn-empty;
    color: $primaryColor;
    font-weight: bold;
  }

  &-action {
    @extend .btn;
    border: 1px solid $lightGrey;
    color: $black;
    font-size: .875rem;
    padding: .5rem 1.5rem;
    display: inline-flex;
    align-items: center;
    font-weight: 900;

    span {
      display: inline-block;
      margin-right: .5rem;
    }
  }

  &-secondary {
    @extend .btn;
    background-color: $primaryIceBlue;
    color: $darkGrey;
    font-weight: 700;
  }  
}

.btn-empty {
  &:focus {
    border: 2px solid blue;
  }
}