@import "../../../assets/styles/vars";
@import "../../../assets/styles/mixins";

.Global-page-wrapper {
    @media (max-width: 979px) {
        .Footer {
            display: none;
        }
    }
}

.Global {

    // @todo refactor !
    &-separator {
        margin: 1em 0;
        border-color: $lightestGrey;
        opacity: .5;
    }

    &-requestInfo-btn {
        width: 100%;
        padding: 1em;

        @media (max-width: 1023px) {
            max-width: 20rem;
        }
    }

    &-printinfo {
        display: none;
    }

    &-page {
        @media (min-width: 1024px) {
            margin-top: 10px;
        }

        &.print {
            .Global {

                &-back,
                &-top-warning,
                &-view-photos-btn,
                &-share,
                &-info--apartment-top,
                &-download,
                &-form-wrapper {
                    display: none;
                }

                &-info {
                    padding: 1rem 0;
                    width: 100%;

                    &--neighborhood {
                        display: block;
                        width: 500px;
                    }
                }

                &-widget {
                    height: 260px;
                }

                &-container {
                    margin-top: 0;
                    padding: 0 1rem;
                    display: block;
                }

                &-includes-container {
                    width: 650px;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                }

                &-include-single {
                    flex: 0 0 100px;
                    margin: 0;
                    padding: .5rem;

                    &__img {
                        height: 30px;
                    }

                    &__text {
                        margin-top: .5rem;
                    }
                }

                &-info-name,
                &-printinfo {
                    display: block;
                }

                &-info-loc {
                    padding: 0;

                    i {
                        position: relative;
                        display: inline-block;
                        padding-right: 15px;
                        font-size: 0;

                        &:before {
                            display: block;
                            height: 1rem;
                            width: 1rem;
                            font-size: 1rem;
                            line-height: 1;
                            position: relative;
                            top: -10px;
                        }
                    }
                }

                &-info-apartments-container {
                    margin: 0;
                }

                &-walkscore {

                    i,
                    span {
                        display: inline-block;
                    }

                    i {
                        padding-right: 15px;
                    }
                }

                &-container__part {
                    margin-left: 0;
                }

                &-map {
                    display: none;
                }
            }
        }
    }

    &-top {
        position: relative;
        font-size: .75rem;
        line-height: 1.33;

        &-warning {
            @media (min-width: 1024px) {
                position: absolute;
                left: 1rem;
                bottom: 1rem;
                border-radius: 6px;
                opacity: .75;
                background: $black;
                padding: .5rem 1rem;
                color: #ffffff;
                font-size: .8em;
            }

            @media (max-width: 1023px) {
                text-align: center;
                margin: .5rem auto;
                color: $midGrey;
                max-width: 20rem;
            }
        }
    }

    &-greysection {
        background-color: #f6f6f6;
    }

    //   &-back {
    //     position: absolute;
    //     top: .1rem;
    //     left: .1rem;
    //     color: #000;
    //     font-size: 1rem;
    //     font-weight: 900;
    //     vertical-align: middle;
    //     padding: 0px;
    //     margin: 0px 0px 10px 0px;

    //     @media print {
    //       display: none;
    //     }
    //   }

    &-return {
        position: absolute;
        top: .1rem;
        left: .1rem;
        color: #000;
        font-size: 1rem;
        font-weight: 900;
        vertical-align: middle;

        @media print {
            display: none;
        }
    }

    &-back:focus {
        outline: none;
        box-shadow: none;
    }

    &-widget-wrapper {
        position: relative;
    }

    &-widget {
        @media (min-width: 1024px) {
            height: 300px;
            display: grid;
            grid-template-columns: 6fr 5fr;
            grid-template-rows: 1fr 1fr;
            grid-row-gap: 2px;
            grid-column-gap: 2px;
            margin-bottom: 30px;
        }

        @media screen and (max-width: 1023px) {
            height: 342px;

            &__item {
                &:not(:first-child) {
                    display: none;
                }

                &:first-child {
                    height: 100%;
                }
            }
        }


        &__item {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
            cursor: pointer;

            &:first-child {
                grid-row-start: 1;
                grid-row-end: 3;
            }
        }
    }

    &-view-photos-btn {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        font-size: .675rem;
        border-radius: $borderRadiusTiny;
        //padding: 1rem 1.75rem;
        font-weight: 900;
        cursor: pointer;
    }

    &-share {}

    &-container {
        //padding: 1rem;

        @media (min-width: 980px) {
            display: flex;
            flex-wrap: nowrap;
        }

        &__part {
            
            &--left {
                flex: 1 1 100%;
                margin-bottom: 2.5rem;
            }

            &--right {
                @media (min-width: 980px) {
                   // flex: 0 0 384px;
                    margin-left: 2rem;
                }

                @media (max-width: 979px) {
                    display: block;
                }
            }
        }
    }

    &-btn-padding {
        padding-left: 5px;
    }

    &-info {
        padding: 1rem 0;

        &-xs {
            &--rate-range {
                display: none;
            }

            @media (min-width: 980px) {
                display: none;
            }

            @media (max-width: 979px) {
                .Global-form-fields-container {
                    border-top: none;
                    margin-top: 0;
                    padding-top: 0;
                }
            }
        }

        &--top {
            padding-top: 0;
        }

        +.Global-info {
            border-top: 3px solid $midLightGrey;

            @media print {
                border: none;
            }
        }

        &-name {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;
            padding-bottom: .5rem;
        }

        &-away {
            @include propertyAway;
        }

        &-loc {
            @include propertyLoc;
        }

        &-heading {
            font-size: 1.25rem;
            margin-bottom: .5rem;
        }

        &-aside {
            font-size: .875rem;
        }

        &-apartments-container {
            margin-top: 2.5rem;
            display: flex;

            @media (max-width: 979px) {
                flex-wrap: wrap;
            }
        }

        &-type {
            text-align: center;
            padding: 0 2rem;

            @media (max-width: 979px) {
                margin-bottom: 1rem;
                min-width: 150px;
                padding: 0;
            }

            &__price {
                margin-top: 1rem;
                font-size: 1rem;
                line-height: 1.25rem;

                i {
                    color: $primaryColor;
                    display: inline-block;
                    margin-right: .5rem;
                }
            }

            &__type {
                margin-top: .5rem;
                font-size: 1rem;
            }
        }

        &-text {
            font-size: 1rem;
            line-height: 1.5rem;
            padding: 1rem;
        }

        &-amenities {
            display: flex;
            max-width: 400px;
            flex-wrap: wrap;
            margin-top: 1rem;
        }
    }

    &-map {
        height: 350px;
        max-width: 100%;
        width: 100%;
        position: relative;
        margin-top: 1.5rem;
    }

    &-includes-container {
        max-width: 100%;
        width: 100%;
        display: flex;
        margin-top: 2.5rem;
        flex-wrap: wrap;

        @media (min-width: 980px) {
            //max-width: 520px;
        }
    }

    &-include-single {
        margin-right: 1rem;
        margin-bottom: 1rem;
        width: 132px;
        background: $veryLightGrey;
        border-radius: $borderRadiusMid;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: .5em 1em;
        text-align: center;

        &__img {
            height: 60px;
        }

        &__text {
            font-size: .625rem;
            font-weight: 900;
            margin-top: 1.1rem;
            letter-spacing: .83px;
            text-transform: uppercase;
        }
    }

    &-amenity {
        font-size: 1rem;
        flex: 0 0 50%;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__img {
            display: inline-block;
            height: 24px;
            margin-right: .5em;
        }

        i {
            color: $primaryColor;
            margin-right: .5rem;
        }
    }

    &-walkscore {
        font-size: .875rem;
        margin-bottom: 1.75rem;

        i {
            color: $primaryColor;
            margin-right: .5rem;
        }
    }

    &-download {
        margin-top: 2rem;
    }

    &-form-wrapper {
        @media (min-width: 980px) {
            background: $white;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);
            border-radius: $borderRadiusMid;
            //   position: fixed;
            padding: 1.5rem;
            width: 444px;
        }
    }

    &-form {
        &-price {
            font-size: 1rem;

            b {
                font-size: 1.25rem;
            }
        }

        &-rating {
            margin-top: .5rem;

            i {
                color: $primaryColor;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &-fields-container {
            border-top: 1px solid $midLightGrey;
            margin-top: 0rem;
            padding-top: 1.5rem;
        }

        .input-block {
            margin: 1rem 0 0;

            &-title {
                font-weight: 900;
                margin-bottom: .5rem;
                font-size: .75rem;
            }
        }

        &-submit {
            display: block;
            width: 100%;
            margin-top: 1.5rem;
            line-height: 3rem;
            font-size: .875rem;
            padding: 0;
            border-radius: $borderRadiusMid;
            text-align: center;
        }

        &-warning {
            margin-top: 1rem;
            font-size: .625rem;
            font-weight: 900;
            letter-spacing: .83px;
            text-transform: uppercase;
            text-align: center;
        }
    }

    &-calendar {
        @include calendarLabelMobile(4000px);
        position: relative;

        .label-xs-container__item {
            line-height: 2.375rem;
        }

        .Calendar {
            border: 1px solid #ccc;
            overflow: hidden;

            &-footer {
                padding: 1rem;
            }
        }

        .rdr-Calendar {
            border: none !important;
        }

        .calendar-cover {
            bottom: auto;
        }
    }

    &-select {
        .Select {
            &-title {
                display: none;
            }

            &-label-container {
                width: 100%;
            }
        }
    }

    &-cancellation-btn {
        color: $primaryColor;
        display: inline-block;
        margin-top: .5rem;
        font-size: .875rem;
        text-decoration: underline;
        cursor: pointer;
    }

    // &-back-btn {
    //     color: #FFFFFF;
    //     display: inline-block;
    //     margin-top: .5rem;
    //     font-size: .875rem;
    //     text-decoration: underline;
    //     cursor: pointer;
    // }

    &-booking-info {
        margin-top: 1.5rem;

        &-empty {
            text-align: center;
            font-size: .875rem;
            max-width: 10.5rem;
            margin: auto;
            padding: 1.5rem 0;
        }
    }

    &-booking-item {
        display: flex;
        justify-content: space-between;
        font-size: .875rem;

        +.Global-booking-item {
            margin-top: .5rem;
        }

        &__value {
            font-weight: 900;
        }
    }

    &-footer {
        @media (min-width: 1024px) {
            display: none;
        }

        @media (max-width: 1023px) {
            border-top: 1px solid $midLightGrey;
            padding: 1rem .5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 99;
            background-color: $white;
            width: 100%;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);

            &__right {
                .btn-cta {
                    font-size: 1rem;
                    padding: 1rem 2rem;

                    i {
                        margin-right: .5rem;
                    }
                }
            }
        }
    }
}

.Cancellation-policy-modal {
    padding: 1.5rem;

    &-heading {
        margin: 0 0 1.5rem;
        font-size: 1rem;
    }
}

.Cancellation-policy-wrapper {
    .Modal-window {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);

        @media (min-width: 620px) {
            width: 40%;
        }

        &-header {
            padding: 0;
            position: relative;

            &-icon-close {
                position: absolute;
                right: 1rem;
                top: 1rem;
            }
        }
    }
}

.Customize-policy-wrapper {
    .Modal-window {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);

        @media (min-width: 620px) {
            width: 40%;
        }

        @media (min-width: 820px) {
            width: 50%;
        }

        &-header {
            padding: 0;
            position: relative;

            &-icon-close {
                position: absolute;
                right: 1rem;
                top: 1rem;
            }
        }
    }
}

.StarRating-policy-modal {
    padding: 1.5rem;

    &-heading {
        margin: 0 0 1.5rem;
        font-size: 1rem;
    }
}

.StarRating-policy-wrapper {
    .Modal-window {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);

        @media (min-width: 620px) {
            width: 400px;
        }

        @media (min-width: 820px) {
            width: 80%;
        }

        &-header {
            padding: 0;
            position: relative;

            &-icon-close {
                position: absolute;
                right: 1rem;
                top: 1rem;
            }
        }
    }
}

.btn-action {
    margin-left: 7px;
}

.sticky {
    position: sticky;
    top: 10%;
    margin-bottom: 100px;

    @media (max-width: 1023px) {
        padding-bottom: 2.5rem;
    }
}

@media print {
    * {
        -webkit-print-color-adjust: exact;
    }

    .Global-top {
        padding-top: 15px;
    }

    .Global-page {
        position: relative;
    }

    .Global-container {
        margin-top: 0;
    }

    .single-page-header,
    .Global-view-photos-btn,
    .Global-footer__right,
    .btn-action,
    .Global-footer,
    .Global-info-xs,
    .Global-info--apartment-top,
    .optanon-alert-box-wrapper {
        display: none !important;
    }

    .Global-widget {
        height: 200px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;


        &__item {
            display: block !important;
            height: 100%;
        }
    }

    .Global-info-amenities {
        max-width: none;
    }

    .Global-info--neighborhood {
        page-break-inside: avoid;
    }

    .Global-amenity {
        flex: 0 0 32%;
    }

    .aco-show-audio-btn {
        display: none;
    }
}

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .smallform {
      width: 50%;
      margin-bottom: 10px;
  }