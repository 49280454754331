@import "mixins";

.address-string {
  @include propertyLoc;

  span {
    line-height: 1.5rem;
    display: inline-block;
    min-height: 1.5rem;
  }
}