@import "../../../assets/styles/vars";
@import "../../../assets/styles/mixins";

.GlobalCustomize {
  &-page {
    @media (min-width: 1024px) {
      padding-top: 85px;
    }
  }

  &-top {
    position: relative;
    font-size: .75rem;
    line-height: 1.33;

    &-warning {
      @media (min-width: 1024px) {
        position: absolute;
        left: 1rem;
        bottom: 1rem;
        border-radius: 6px;
        opacity: .75;
        background: $black;
        padding: .5rem 1rem;
        color: #ffffff;
      }

      @media (max-width: 1023px) {
        text-align: center;
        margin: .5rem auto;
        color: $midGrey;
        max-width: 20rem;
      }
    }
  }

  &-back {
    position: absolute;
    top: 2rem;
    left: 2rem;
    color: #fff;
    font-size: .875rem;
    font-weight: 900;
  }

  &-widget-wrapper {
    position: relative;
  }

  &-widget {
    @media (min-width: 1024px) {
      height: 576px;
      display: grid;
      grid-template-columns: 8fr 6fr;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 2px;
      grid-column-gap: 2px;
    }

    @media (max-width: 1023px) {
      height: 342px;

      &__item {
        &:not(:first-child) {
          display: none;
        }

        &:first-child {
          height: 100%;
        }
      }
    }

    &__item {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      cursor: pointer;

      &:first-child {
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  }

  &-view-photos-btn {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: .875rem;
    border-radius: $borderRadiusMid;
    padding: 1rem 1.75rem;
    font-weight: 900;
    cursor: pointer;
  }

  &-share {
  }

  &-container {
    display: flex;
    flex-wrap: nowrap;

    @media (min-width: 980px) {
      flex-direction: row;
    }

    @media (max-width: 979px) {
      flex-direction: column;
    }

    &__part {
      &--left {
        flex: 1 1 100%;
        // margin-bottom: 2.5rem;
        background-color: $white;

        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
  }

  &-info {
    padding: 2.5rem 0;

    &-xs {
      @media (min-width: 980px) {
        display: none;
      }

      @media (max-width: 979px) {
        padding-top: 1rem !important;

        .GlobalCustomize-form-fields-container {
          border-top: none;
          margin-top: 0;
          padding-top: 0;
        }
      }
    }

    &--top {
      padding-top: 0;
    }

    + .GlobalCustomize-info {
      border-top: 1px solid $midLightGrey;
    }

    &-name {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: .5rem;
    }

    &-away {
      @include propertyAway;
    }

    &-loc {
      @include propertyLoc;
      font-size: 0.875rem;
    }

    &-heading {
      font-size: 1.25rem;
      margin-bottom: .5rem;
    }

    &-aside {
      font-size: .875rem;

      > .icon-bold-arrow-right {
        font-size: .7rem;
        color: $green;
        padding: 0 3rem;
      }
    }

    &-apartments-container {
      margin-top: 2.5rem;
      display: flex;

      @media (max-width: 979px) {
        flex-wrap: wrap;
      }
    }

    &-type {
      text-align: center;
      padding: 0 2rem;

      @media (max-width: 979px) {
        margin-bottom: 1rem;
        min-width: 150px;
        padding: 0;
      }

      &__price {
        margin-top: 1rem;
        font-size: 1rem;
        line-height: 1.25rem;

        i {
          color: $primaryColor;
          display: inline-block;
          margin-right: .5rem;
        }
      }

      &__type {
        margin-top: .5rem;
        font-size: 1rem;
      }
    }

    &-text {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &-amenities {
      // display: flex;
      max-width: 400px;
      // flex-wrap: wrap;
      margin-top: 1.5rem;
    }
  }

  &-map {
    height: 244px;
    max-width: 400px;
    width: 100%;
    position: relative;
    margin-top: 1.5rem;
  }

  &-includes-container {
    display: flex;
    // max-width: 520px;
    margin-top: 2.5rem;
    flex-wrap: wrap;
  }

  &-include-single {
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: 152px;
    height: 100px;
    background: $veryLightGrey;
    border-radius: $borderRadiusMid;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__text {
      font-size: .625rem;
      font-weight: 900;
      margin-top: 1.1rem;
      letter-spacing: .83px;
    }
  }

  &-amenity {
    font-size: 0.85rem;
    // flex: 0 0 50%;
    margin-bottom: 1.5rem;

    i {
      font-size: 0.5rem;
      color: $primaryColor;
      margin-right: .5rem;
      margin-left: .5rem;
    }
  }

  &-walkscore {
    font-size: .875rem;
    margin-bottom: 1.75rem;

    i {
      color: $primaryColor;
      margin-right: .5rem;
    }
  }

  &-download {
    margin-top: 2rem;
  }

  &-form-wrapper {
    @media (min-width: 980px) {
      background: $veryLightGrey;
      // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);
      // border-radius: $borderRadiusMid;
      // position: sticky;
      // padding: 0 0 9.5rem 0;
      padding: 0;
      min-height: 100%;
    }
  }

  &-calendar {
    @include calendarLabelMobile(4000px);
    position: relative;

    .label-xs-container__item {
      line-height: 2.375rem;
    }

    .Calendar {
      border: 1px solid #ccc;
      overflow: hidden;

      &-footer {
        padding: 1rem;
      }
    }

    .rdr-Calendar {
      border: none !important;
    }
  }

  &-select {
    .Select {
      &-title {
        display: none;
      }

      &-label-container {
        width: 100%;
      }
    }
  }

  &-cancellation-btn {
    color: $primaryColor;
    display: inline-block;
    margin-top: .5rem;
    font-size: .875rem;
    text-decoration: underline;
    cursor: pointer;
  }

  &-booking-info {
    margin-top: 1.5rem;
  }

  &-booking-item {
    display: flex;
    justify-content: space-between;
    font-size: .875rem;
    margin-top: .5rem;

    + .GlobalCustomize-Global-item {
      margin-top: 1rem;
    }

    &__value {
      font-weight: 900;
    }
  }

  &-footer {
    @media (min-width: 1024px) {
      display: none;
    }

    @media (max-width: 1023px) {
      border-top: 1px solid $midLightGrey;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;

      &__right {
        button {
          font-size: 1rem;
          padding: 1rem 2rem;

          i {
            margin-right: .5rem;
          }
        }
      }
    }
  }

  &-customize {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    flex: 1 0 auto;
  }
}

.Cancellation-policy-modal {
  padding: 1.5rem;

  &-heading {
    margin: 0 0 1.5rem;
    font-size: 1rem;

    &-amenities {
      font-size: 0.85rem;
    }
  }
}

.Cancellation-policy-wrapper {
  .Modal-window {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);

    @media (min-width: 620px) {
      width: 400px;
    }

    @media (min-width: 820px) {
      width: 600px;
    }

    &-header {
      padding: 0;
      position: relative;

      &-icon-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
      }
    }
  }
}

.border-top-midlightgrey {
  border-top: 1px solid $midLightGrey;
}

.border-top-lightgrey {
  border-top: 1px solid $lightGrey;
}

.property-png-PH {
  background-image: url("../img/drafts/property.png");
  height: 216px;
}

.font-small {
  font-size: 0.875rem;
}

.addOns {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
}

.modal {
  &-footer {
    background-color: $veryLightGrey;
    border-top: 1px solid $midGrey;
    border-radius: 0 0 8px 8px;
    padding: 1rem;

    display: flex;
    justify-content: space-between;
  }

  &-ctrl {
    cursor: pointer;
    font-weight: bolder;

    &.clear {
      color: $black;
    }

    &.apply {
      color: $primaryColor;
    }
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;
}
  
.tooltip-container .tooltip {
  visibility: hidden;
  width: 300px;
  background-color: $darkGrey;
  color: $white;
  text-align: center;
  border-radius: 6px;
  padding: .5rem;
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: 50%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.875rem;
}

.tooltip-container .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: $darkGrey transparent transparent transparent;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.small {
  padding: 0 1rem 1rem;

  @media (min-width: 980px) {
    display: none;
  }

  h1 {
    font-size: 1.75rem;
  }

  .GlobalCustomize {
    &-small {
      &-form {
        padding-top: 1.5rem;

        .input-block {
          margin: 0;
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }

    &-info-apartments-container {
      flex-direction: column;
    }

    &-info-type {
      display: flex;

      .custom-radio-icon {
        margin-right: .75rem;
      }

      &__price { 
        margin-top: 0;
        margin-right: .75rem;
      }

      &__type {
        margin-top: 0.1rem;
      }
    }

    &-rates {
      background: $veryLightGrey;
      margin-top: 1rem;
      margin-bottom: 1rem;

      border-radius: $borderRadiusMid;
      border: 1px solid $lightGrey;
      padding: 1rem;

      .input-block {
        margin: 1rem 0 0;

        &-title {
          font-weight: 900;
          margin-bottom: .5rem;
          font-size: .75rem;
        }
      }
    }
  }

  .customize-calendar-cover {
    display: none;
    position: absolute;
    left: 0;
    bottom: auto;
    z-index: 10;
    border-radius: 0 0 $borderRadiusMid $borderRadiusMid !important;
    background-color: $white;
    overflow: hidden;

    // @media (min-width: $view) {
    //   box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
    //   transform: translateY(98%);
    //   border-top: 1px solid $veryLightGrey;
    // }

    // @media (max-width: $view - 1) {
      width: 100%;
      top: 82%;
    // }
  }

  .visualController:checked {
    & ~ .customize-calendar-cover {
      display: block;
    }

    ~ .input-block-label {
      .label-xs-container {
        &__item {
          flex: 0 0 50%;

          &:first-child {
            border-radius: $borderRadiusMid 0 0 0;
            border-right: 0;
          }

          &:last-child {
            border-radius: 0 $borderRadiusMid 0 0;
          }
        }
      }
    }
  }
}

.large {
  @media (max-width: 979px) {
    display: none;
  }
}

.addOns {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
}

.modal-footer {
  background-color: $veryLightGrey;
  border-top: 1px solid $midGrey;
  border-radius: 0 0 8px 8px;
  padding: 1rem;

  display: flex;
  justify-content: flex-end;
}

.warning {
    color: red;
}


