/**
* _main stylesheet 
*/

@import "vars";
// @import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
  color-adjust: exact !important;                 /*Firefox*/
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-size: 16px;
  color: $textColor;
  font-family: 'Avenir';
}

a {
  text-decoration: none;
  color: $primaryDark;
}

input,
button,
textarea,
select {
  font-family: 'Avenir';
}

label.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  display: none;
}

input[type="checkbox"].visualController:checked ~ label.backdrop {
  display: block;
}

input[type="checkbox"].visualController:checked ~ .opensWithCheckBox {
  display: inline-block;
}

ul {
  list-style-type: none;
}

.container {
  max-width: 1280px;
  width: 100%;
  padding: 0 2em;
  margin: 0 auto;
}

@media print {
  @page { 
    margin: 0;
    size: Letter;
  }

  .uai {
    display: none !important;
  }
}
