/**
* fonts stylesheet 
*/

@font-face {
  font-family: 'icomoon';
  src:  url('./icons/icomoon/fonts/icomoon.eot?yqo1v5');
  src:  url('./icons/icomoon/fonts/icomoon.eot?yqo1v5#iefix') format('embedded-opentype'),
  url('./icons/icomoon/fonts/icomoon.ttf?yqo1v5') format('truetype'),
  url('./icons/icomoon/fonts/icomoon.woff?yqo1v5') format('woff'),
  url('./icons/icomoon/fonts/icomoon.svg?yqo1v5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Linotype-AvenirLTPro-Heavy.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Linotype-AvenirLTPro-Book.otf');
  font-weight: 300;
  font-style: normal;
}