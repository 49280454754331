$primaryColor:    #3e93ba;
$primaryIceBlue:  #ebf4f8;
$primaryLight:    #cadde9;
$primaryDark:     rgb(72, 124, 153);
$secondaryColor:  rgb(150, 189, 210);

$lightBlue:       rgb(124, 166, 215);
$green:           #96b42b;
$white:           rgb(255, 255, 255);

$lightestGrey:    rgb(247, 247, 247);
$veryLightGrey:   #f9f9f9;
$midLightGrey:    #efefef;
$lightGrey:       #d3d1d1;
$midGrey:         #767676;
$darkGrey:        #454545;
$black:           #272727;
$red:             red;

$borderRadiusTiny: 3px;
$borderRadiusSmall: 5px;
$borderRadiusMid: 8px;
$borderRadiusBig: 15px;

$boxShadow: rgba(0, 0, 0, 0.14);

$textColor: $black;
$lightText: $midGrey;
$headingColor: $darkGrey;
