@import "../styles/vars";
@import "../styles/mixins";

.GlobalPrices {
  &-container {
    &__part {
      &--right {
        background-color: $veryLightGrey;

        @media (min-width: 980px) {
          flex: 0 0 33%;
          // margin-left: 2rem;
        }

        @media (max-width: 979px) {
          display: none;
          // flex: 0 0 100%;
          // order: -1;
        }
      }
    }
  }

  &-info {
    padding: 2.5rem 0;

    &-xs {
      @media (min-width: 980px) {
        display: none;
      }

      @media (max-width: 979px) {
        .GlobalPrices-form-fields-container {
          border-top: none;
          margin-top: 0;
          padding-top: 0;
        }
      }
    }

    &--top {
      padding-top: 0;
    }

    + .GlobalPrices-info {
      border-top: 1px solid $midLightGrey;
    }

    &-name {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: .5rem;
    }

    &-away {
      @include propertyAway;
    }

    &-loc {
      @include propertyLoc;
      font-size: 0.875rem;
    }

    &-heading {
      font-size: 1.25rem;
      margin-bottom: .5rem;
    }

    &-aside {
      font-size: .875rem;

      > .icon-bold-arrow-right {
        font-size: .7rem;
        color: $green;
        padding: 0 3rem;
      }
    }

    &-apartments-container {
      margin-top: 2.5rem;
      display: flex;

      @media (max-width: 979px) {
        flex-wrap: wrap;
      }
    }

    &-type {
      text-align: center;
      padding: 0 2rem;

      @media (max-width: 979px) {
        margin-bottom: 1rem;
        min-width: 150px;
        padding: 0;
      }

      &__price {
        margin-top: 1rem;
        font-size: 1rem;
        line-height: 1.25rem;

        i {
          color: $primaryColor;
          display: inline-block;
          margin-right: .5rem;
        }
      }

      &__type {
        margin-top: .5rem;
        font-size: 1rem;
      }
    }

    &-text {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  &-map {
    height: 244px;
    max-width: 400px;
    width: 100%;
    position: relative;
    margin-top: 1.5rem;
  }

  &-walkscore {
    font-size: .875rem;
    margin-bottom: 1.75rem;

    i {
      color: $primaryColor;
      margin-right: .5rem;
    }
  }

  &-download {
    margin-top: 2rem;
  }

  &-form-wrapper {
    @media (min-width: 980px) {
      background: $veryLightGrey;
      // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);
      // border-radius: $borderRadiusMid;
      // position: sticky;
      // padding: 0 0 9.5rem 0;
      padding: 0;
      min-height: 100%;
    }
  }

  &-form {
    @media (min-width: 980px) {
      padding: 1rem 6rem 1rem 2rem;
    }

    @media (max-width: 979px) {
      padding: 1.5rem;
    }

    &-price {
      font-size: 1rem;

      b {
        font-size: 1.25rem;
      }
    }

    &-rating {
      margin-top: .5rem;

      i {
        color: $primaryColor;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    &-fields-container {
      border-top: 1px solid $midLightGrey;
      margin-top: 1.5rem;
      padding-top: 1.5rem;
    }

    .input-block {
      margin: 1rem 0 0;

      &-title {
        font-weight: 900;
        margin-bottom: .5rem;
        font-size: .75rem;
      }
    }

    &-submit {
      display: block;
      width: 100%;
      margin-top: 1.5rem;
      line-height: 3rem;
      font-size: .875rem;
      padding: 0;
      border-radius: $borderRadiusMid;
    }

    &-warning {
      margin-top: 1rem;
      font-size: .625rem;
      font-weight: 900;
      letter-spacing: .83px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  &-calendar {
    @include calendarLabelMobile(4000px);
    position: relative;

    .label-xs-container__item {
      line-height: 2.375rem;
    }

    .Calendar {
      border: 1px solid #ccc;
      overflow: hidden;

      &-footer {
        padding: 1rem;
      }
    }

    .rdr-Calendar {
      border: none !important;
    }
  }

  &-select {
    .Select {
      &-title {
        display: none;
      }

      &-label-container {
        width: 100%;
      }
    }
  }

  &-cancellation-btn {
    color: $primaryColor;
    display: inline-block;
    margin-top: .5rem;
    font-size: .875rem;
    text-decoration: underline;
    cursor: pointer;
  }

  &-booking-info {
    margin-top: 1.5rem;
  }

  &-booking-item {
    display: flex;
    justify-content: space-between;
    font-size: .875rem;
    margin-top: .5rem;

    + .GlobalPrices-booking-item {
      margin-top: 1rem;
    }

    &__value {
      font-weight: 900;
    }
  }

  &-footer {
    @media (min-width: 1024px) {
      display: none;
    }

    @media (max-width: 1023px) {
      border-top: 1px solid $midLightGrey;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;

      &__right {
        button {
          font-size: 1rem;
          padding: 1rem 2rem;

          i {
            margin-right: .5rem;
          }
        }
      }
    }
  }

  &-customize {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

.Cancellation-policy-modal {
  padding: 1.5rem;

  &-heading {
    margin: 0 0 1.5rem;
    font-size: 1rem;

    &-amenities {
      font-size: 0.85rem;
    }
  }
}

.Cancellation-policy-wrapper {
  .Modal-window {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);

    @media (min-width: 620px) {
      width: 400px;
    }

    @media (min-width: 820px) {
      width: 600px;
    }

    &-header {
      padding: 0;
      position: relative;

      &-icon-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
      }
    }
  }
}

.border-top-midlightgrey {
  border-top: 1px solid $midLightGrey;
}

.border-top-lightgrey {
  border-top: 1px solid $lightGrey;
}

.property-png-PH {
  background-image: url("../img/drafts/property.png");
  height: 216px;
}

.font-small {
  font-size: 0.875rem;
}

.addOns {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
}

.modal-footer {
  background-color: $veryLightGrey;
  border-top: 1px solid $midGrey;
  border-radius: 0 0 8px 8px;
  padding: 1rem;

  display: flex;
  justify-content: space-between;
}

.modal-ctrl {
  cursor: pointer;
  font-weight: bolder;

  &.clear {
    color: $black;
  }

  &.apply {
    color: $primaryColor;
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;
}
  
.tooltip-container .tooltip {
  visibility: hidden;
  width: 300px;
  background-color: $darkGrey;
  color: $white;
  text-align: center;
  border-radius: 6px;
  padding: .5rem;
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: 50%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.875rem;
}

.tooltip-container .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: $darkGrey transparent transparent transparent;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
