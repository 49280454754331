/**
* _icons stylesheet 
*/

@import "vars";

%icon:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icons: (
  bold-arrow-right: "\e918",
  close: "\e919",
  download: "\e91a",
  filters: "\e91b",
  info: "\e91c",
  share: "\e91d",
  sorting-path1: "\e91e",
  sorting-path2: "\e91f",
  air-conditioning: "\e900",
  apartment-type: "\e901",
  arrow-down: "\e902",
  arrow-left: "\e903",
  arrow-right: "\e904",
  arrow-up: "\e905",
  business-room: "\e906",
  calendar: "\e907",
  checkmark: "\e908",
  club-house: "\e909",
  concierge: "\e90a",
  fitness-area: "\e90b",
  generic: "\e90c",
  heart: "\e90d",
  instant-booking: "\e90e",
  location: "\e90f",
  media-room: "\e910",
  parking: "\e911",
  pet-friendly: "\e912",
  pool: "\e913",
  search: "\e914",
  star-full: "\e915",
  star-left: "\e916",
  star-right: "\e917",
);

@each $name, $code in $icons {
  .icon-#{$name}:before {
      @extend %icon;
      content: $code;
  }
}

.custom-icon-check {
  height: 24px;
  width: 24px;
  display: inline-block;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    background: $primaryColor;
    position: absolute;
    margin: auto;
    width: 2px;
  }

  &:before {
    height: 16px;
    transform: rotate(40deg);
    top: 0;
    left: 0;
    right: 0;
    bottom: 5px;
  }

  &:after {
    height: 8px;
    bottom: 0;
    top: 5px;
    left: 0;
    right: 18px;
    transform: rotate(-50deg);
  }
}

.custom-icon-dot {
  height: 24px;
  width: 24px;
  display: inline-block;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    background: $primaryColor;
    position: absolute;
    margin: auto;
    width: 2px;
  }

  &:before {
    height: 16px;
    transform: rotate(40deg);
    top: 0;
    left: 0;
    right: 0;
    bottom: 5px;
  }

  &:after {
    height: 8px;
    bottom: 0;
    top: 5px;
    left: 0;
    right: 18px;
    transform: rotate(-50deg);
  }
}


