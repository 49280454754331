@mixin formElement {
  line-height: 2.875rem;
  border: 1px solid #ccc;
  border-radius: 8px;
}

@mixin iconFormElement {
  @include formElement;
  position: relative;
  padding-left: 2.75rem;

  &:before {
    position: absolute;
    font-size: 1rem;
    color: $lightGrey;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    left: .75rem;
  }
}

@mixin iconSelect {
  @include iconFormElement;

  &:after {
    content: "\e902";
    font-family: 'icomoon' !important;
    height: 1rem;
    width: 1rem;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 1rem;
    color: $primaryColor;
    font-size: .75rem;
    line-height: 1rem;
    text-align: center;
  }
}

@mixin input{
  @include formElement;
}

@mixin calendarLabelMobile($view) {
  @media (max-width: $view - 1) {
    .input-block-label {
      padding: 0;

      &--calendar {
        width: 100%;
      }

      &:before {
        display: none;
      }
    }
  }

  .label-lg-container {
    @media (max-width: $view - 1) {
      display: none;
    }
  }

  .label-xs-container {
    @media (min-width: $view) {
      display: none;
    }

    @media (max-width: $view - 1) {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &__item {
        @include iconFormElement;
        padding-left: 2.5rem;
        flex: 0 0 48%;
        display: block;
        font-size: .875rem;
        min-height: 2.5rem;
        color: $black;
      }
    }
  }

  > .input-block {
    &-titles-xs {
      @media (min-width: $view) {
        display: none;
      }

      @media (max-width: $view - 1) {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .input-block-title {
          flex: 0 0 48%;
          font-size: .75rem;
        }
      }
    }

    &-title-lg {
      @media (max-width: $view - 1) {
        display: none;
      }
    }
  }

  .calendar-cover {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    border-radius: 0 0 $borderRadiusMid $borderRadiusMid !important;
    background-color: $white;
    overflow: hidden;

    @media (min-width: $view) {
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
      transform: translateY(98%);
      border-top: 1px solid $veryLightGrey;
    }

    @media (max-width: $view - 1) {
      width: 100%;
      top: 98%;
    }
  }

  .visualController:checked {
    & ~ .calendar-cover {
      display: block;
    }

    @media (max-width: $view - 1) {
      ~ .input-block-label {
        .label-xs-container {
          &__item {
            flex: 0 0 50%;

            &:first-child {
              border-radius: $borderRadiusMid 0 0 0;
              border-right: 0;
            }

            &:last-child {
              border-radius: 0 $borderRadiusMid 0 0;
            }
          }
        }
      }
    }
  }
}

@mixin propertyAway {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $midGrey;
  margin-top: .5rem;
  font-size: .75rem;
}

@mixin propertyLoc {
  margin-top: 1rem;
  padding-left: 2rem;
  position: relative;

  i {
    position: absolute;
    top: .25rem;
    left: 0;
    color: $lightGrey;
  }

  span {
    position: relative;
    top: 3px;
  }
}

@mixin infoWindow {
  border-radius: $borderRadiusMid;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
