/**
* _helpers stylesheet 
*/

@import "vars";

@for $i from 0 through 9 {
  .m {
    &-#{$i} {
      &:not(&-px) {
        margin: #{$i}em !important;
      }

      &-px {
        margin: #{$i}px !important;
      }
    }

    &-0#{$i} {
      margin: $i * 0.1em !important;
    }

    &b {
      &-#{$i} {
        &:not(&-px) {
          margin-bottom: #{$i}em !important;
        }

        &-px {
          margin-bottom: #{$i}px !important;
        }
      }

      &-0#{$i} {
        margin-bottom: $i * 0.1em !important;
      }
    }

    &t {
      &-#{$i} {
        &:not(&-px) {
          margin-top: #{$i}em !important;
        }

        &-px {
          margin-top: #{$i}px !important;
        }
      }

      &-0#{$i} {
        margin-top: $i * 0.1em !important;
      }
    }

    &l {
      &-#{$i} {
        &:not(&-px) {
          margin-left: #{$i}em !important;
        }

        &-px {
          margin-left: #{$i}px !important;
        }
      }

      &-0#{$i} {
        margin-left: $i * 0.1em !important;
      }
    }

    &r {
      &-#{$i} {
        &:not(&-px) {
          margin-right: #{$i}em !important;
        }

        &-px {
          margin-right: #{$i}px !important;
        }
      }

      &-0#{$i} {
        margin-right: $i * 0.1em !important;
      }
    }
  }

  .p {
    &-#{$i} {
      &:not(&-px) {
        padding: #{$i}em !important;
      }

      &-px {
        padding: #{$i}px !important;
      }
    }

    &-0#{$i} {
      padding: $i * 0.1em !important;
    }

    &b {
      &-#{$i} {
        &:not(&-px) {
          padding-bottom: #{$i}em !important;
        }

        &-px {
          padding-bottom: #{$i}px !important;
        }
      }

      &-0#{$i} {
        padding-bottom: $i * 0.1em !important;
      }
    }

    &t {
      &-#{$i} {
        &:not(&-px) {
          padding-top: #{$i}em !important;
        }

        &-px {
          padding-top: #{$i}px !important;
        }
      }

      &-0#{$i} {
        padding-top: $i * 0.1em !important;
      }
    }

    &l {
      &-#{$i} {
        &:not(&-px) {
          padding-left: #{$i}em !important;
        }

        &-px {
          padding-left: #{$i}px !important;
        }
      }

      &-0#{$i} {
        padding-left: $i * 0.1em !important;
      }
    }

    &r {
      &-#{$i} {
        &:not(&-px) {
          padding-right: #{$i}em !important;
        }

        &-px {
          padding-right: #{$i}px !important;
        }
      }

      &-0#{$i} {
        padding-right: $i * 0.1em !important;
      }
    }
  }

  .b {
    &-#{$i} {
      &:not(&-px) {
        border: #{$i}em !important;
      }

      &-px {
        border: #{$i}px !important;
      }
    }

    &-0#{$i} {
      border: $i * 0.1em !important;
    }

    &b {
      &-#{$i} {
        &:not(&-px) {
          border-bottom: #{$i}em !important;
        }

        &-px {
          border-bottom: #{$i}px !important;
        }
      }

      &-0#{$i} {
        border-bottom: $i * 0.1em !important;
      }
    }

    &t {
      &-#{$i} {
        &:not(&-px) {
          border-top: #{$i}em !important;
        }

        &-px {
          border-top: #{$i}px !important;
        }
      }

      &-0#{$i} {
        border-top: $i * 0.1em !important;
      }
    }

    &l {
      &-#{$i} {
        &:not(&-px) {
          border-left: #{$i}em !important;
        }

        &-px {
          border-left: #{$i}px !important;
        }
      }

      &-0#{$i} {
        border-left: $i * 0.1em !important;
      }
    }

    &r {
      &-#{$i} {
        &:not(&-px) {
          border-right: #{$i}em !important;
        }

        &-px {
          border-right: #{$i}px !important;
        }
      }

      &-0#{$i} {
        border-right: $i * 0.1em !important;
      }
    }
  }

  .w {
    &-#{(($i + 1) * 10) - 5} {
      width: ((($i + 1) * 10%) - 5%) !important;
    }

    &-#{($i + 1) * 10} {
      width: (($i + 1) * 10%) !important;
    }
  }
}
