@charset "UTF-8";
/**
* fonts stylesheet 
*/
@import url(app.css);
@import url(../../../node_modules/bootstrap/dist/css/bootstrap.min.css);
@import url(../../../node_modules/react-image-lightbox/style.css);
@import url(../../../node_modules/react-datepicker/dist/react-datepicker.css);
@font-face {
  font-family: 'icomoon';
  src: url("./icons/icomoon/fonts/icomoon.eot?yqo1v5");
  src: url("./icons/icomoon/fonts/icomoon.eot?yqo1v5#iefix") format("embedded-opentype"), url("./icons/icomoon/fonts/icomoon.ttf?yqo1v5") format("truetype"), url("./icons/icomoon/fonts/icomoon.woff?yqo1v5") format("woff"), url("./icons/icomoon/fonts/icomoon.svg?yqo1v5#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Avenir';
  src: url("../fonts/Linotype-AvenirLTPro-Heavy.otf");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Avenir';
  src: url("../fonts/Linotype-AvenirLTPro-Book.otf");
  font-weight: 300;
  font-style: normal; }

/**
* _icons stylesheet 
*/
.icon-bold-arrow-right:before, .icon-close:before, .icon-download:before, .icon-filters:before, .icon-info:before, .icon-share:before, .icon-sorting-path1:before, .icon-sorting-path2:before, .icon-air-conditioning:before, .icon-apartment-type:before, .icon-arrow-down:before, .icon-arrow-left:before, .icon-arrow-right:before, .icon-arrow-up:before, .icon-business-room:before, .icon-calendar:before, .icon-checkmark:before, .input-block-checkbox:checked + .input-block-label:before, .icon-club-house:before, .icon-concierge:before, .icon-fitness-area:before, .icon-generic:before, .icon-heart:before, .icon-instant-booking:before, .icon-location:before, .icon-media-room:before, .icon-parking:before, .icon-pet-friendly:before, .icon-pool:before, .icon-search:before, .icon-star-full:before, .icon-star-left:before, .icon-star-right:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-bold-arrow-right:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-download:before {
  content: ""; }

.icon-filters:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-share:before {
  content: ""; }

.icon-sorting-path1:before {
  content: ""; }

.icon-sorting-path2:before {
  content: ""; }

.icon-air-conditioning:before {
  content: ""; }

.icon-apartment-type:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-business-room:before {
  content: ""; }

.icon-calendar:before {
  content: ""; }

.icon-checkmark:before, .input-block-checkbox:checked + .input-block-label:before {
  content: ""; }

.icon-club-house:before {
  content: ""; }

.icon-concierge:before {
  content: ""; }

.icon-fitness-area:before {
  content: ""; }

.icon-generic:before {
  content: ""; }

.icon-heart:before {
  content: ""; }

.icon-instant-booking:before {
  content: ""; }

.icon-location:before {
  content: ""; }

.icon-media-room:before {
  content: ""; }

.icon-parking:before {
  content: ""; }

.icon-pet-friendly:before {
  content: ""; }

.icon-pool:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-star-full:before {
  content: ""; }

.icon-star-left:before {
  content: ""; }

.icon-star-right:before {
  content: ""; }

.custom-icon-check {
  height: 24px;
  width: 24px;
  display: inline-block;
  position: relative; }
  .custom-icon-check:before, .custom-icon-check:after {
    content: '';
    display: block;
    background: #3e93ba;
    position: absolute;
    margin: auto;
    width: 2px; }
  .custom-icon-check:before {
    height: 16px;
    transform: rotate(40deg);
    top: 0;
    left: 0;
    right: 0;
    bottom: 5px; }
  .custom-icon-check:after {
    height: 8px;
    bottom: 0;
    top: 5px;
    left: 0;
    right: 18px;
    transform: rotate(-50deg); }

.custom-icon-dot {
  height: 24px;
  width: 24px;
  display: inline-block;
  position: relative; }
  .custom-icon-dot:before, .custom-icon-dot:after {
    content: '';
    display: block;
    background: #3e93ba;
    position: absolute;
    margin: auto;
    width: 2px; }
  .custom-icon-dot:before {
    height: 16px;
    transform: rotate(40deg);
    top: 0;
    left: 0;
    right: 0;
    bottom: 5px; }
  .custom-icon-dot:after {
    height: 8px;
    bottom: 0;
    top: 5px;
    left: 0;
    right: 18px;
    transform: rotate(-50deg); }

/**
* _main stylesheet 
*/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  color-adjust: exact !important;
  /*Firefox*/ }

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-size: 16px;
  color: #272727;
  font-family: 'Avenir'; }

a {
  text-decoration: none;
  color: #487c99; }

input,
button,
textarea,
select {
  font-family: 'Avenir'; }

label.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  display: none; }

input[type="checkbox"].visualController:checked ~ label.backdrop {
  display: block; }

input[type="checkbox"].visualController:checked ~ .opensWithCheckBox {
  display: inline-block; }

ul {
  list-style-type: none; }

.container {
  max-width: 1280px;
  width: 100%;
  padding: 0 2em;
  margin: 0 auto; }

@media print {
  @page {
    margin: 0;
    size: Letter; }
  .uai {
    display: none !important; } }

/**
* form stylesheet 
*/
/**
* _icons stylesheet 
*/
.icon-bold-arrow-right:before, .icon-close:before, .icon-download:before, .icon-filters:before, .icon-info:before, .icon-share:before, .icon-sorting-path1:before, .icon-sorting-path2:before, .icon-air-conditioning:before, .icon-apartment-type:before, .icon-arrow-down:before, .icon-arrow-left:before, .icon-arrow-right:before, .icon-arrow-up:before, .icon-business-room:before, .icon-calendar:before, .icon-checkmark:before, .input-block-checkbox:checked + .input-block-label:before, .icon-club-house:before, .icon-concierge:before, .icon-fitness-area:before, .icon-generic:before, .icon-heart:before, .icon-instant-booking:before, .icon-location:before, .icon-media-room:before, .icon-parking:before, .icon-pet-friendly:before, .icon-pool:before, .icon-search:before, .icon-star-full:before, .icon-star-left:before, .icon-star-right:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-bold-arrow-right:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-download:before {
  content: ""; }

.icon-filters:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-share:before {
  content: ""; }

.icon-sorting-path1:before {
  content: ""; }

.icon-sorting-path2:before {
  content: ""; }

.icon-air-conditioning:before {
  content: ""; }

.icon-apartment-type:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-business-room:before {
  content: ""; }

.icon-calendar:before {
  content: ""; }

.icon-checkmark:before, .input-block-checkbox:checked + .input-block-label:before {
  content: ""; }

.icon-club-house:before {
  content: ""; }

.icon-concierge:before {
  content: ""; }

.icon-fitness-area:before {
  content: ""; }

.icon-generic:before {
  content: ""; }

.icon-heart:before {
  content: ""; }

.icon-instant-booking:before {
  content: ""; }

.icon-location:before {
  content: ""; }

.icon-media-room:before {
  content: ""; }

.icon-parking:before {
  content: ""; }

.icon-pet-friendly:before {
  content: ""; }

.icon-pool:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-star-full:before {
  content: ""; }

.icon-star-left:before {
  content: ""; }

.icon-star-right:before {
  content: ""; }

.custom-icon-check {
  height: 24px;
  width: 24px;
  display: inline-block;
  position: relative; }
  .custom-icon-check:before, .custom-icon-check:after {
    content: '';
    display: block;
    background: #3e93ba;
    position: absolute;
    margin: auto;
    width: 2px; }
  .custom-icon-check:before {
    height: 16px;
    transform: rotate(40deg);
    top: 0;
    left: 0;
    right: 0;
    bottom: 5px; }
  .custom-icon-check:after {
    height: 8px;
    bottom: 0;
    top: 5px;
    left: 0;
    right: 18px;
    transform: rotate(-50deg); }

.custom-icon-dot {
  height: 24px;
  width: 24px;
  display: inline-block;
  position: relative; }
  .custom-icon-dot:before, .custom-icon-dot:after {
    content: '';
    display: block;
    background: #3e93ba;
    position: absolute;
    margin: auto;
    width: 2px; }
  .custom-icon-dot:before {
    height: 16px;
    transform: rotate(40deg);
    top: 0;
    left: 0;
    right: 0;
    bottom: 5px; }
  .custom-icon-dot:after {
    height: 8px;
    bottom: 0;
    top: 5px;
    left: 0;
    right: 18px;
    transform: rotate(-50deg); }

.input-hidden, .input-block-radio {
  opacity: 0;
  z-index: -999;
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden; }

.input-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 .5em; }
  .input-block-title {
    text-transform: uppercase;
    flex: 0 0 100%;
    font-size: 0.85em;
    color: #454545; }
    @media (max-width: 839px) {
      .input-block-title {
        font-weight: 900;
        font-size: .75rem;
        margin-bottom: .5rem; } }
  .input-block-input, .input-block-select {
    width: 100%;
    font-size: 1em;
    padding: .75em;
    border-radius: 5px;
    border: 1px solid #efefef;
    background-color: white;
    cursor: pointer; }
  .input-block-input-wrapper {
    display: flex;
    align-items: center;
    align-content: center; }
  .input-block-select {
    display: block;
    height: 2.15em; }
    .input-block-select-wrapper {
      position: relative;
      width: 100%; }
      .input-block-select-wrapper:after {
        position: absolute;
        right: .1em;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        background-color: white;
        pointer-events: none;
        font-size: 1em;
        padding: .45em; }
  .input-block-checkbox + .input-block-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    text-transform: capitalize;
    margin: .5em 0;
    padding-left: 1.7em; }
    .input-block-checkbox + .input-block-label:after {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      display: block;
      border: 1px solid #767676;
      border-radius: 3px;
      width: 1.2em;
      height: 1.2em; }
  .input-block-checkbox:checked + .input-block-label:before {
    color: #3e93ba;
    font-size: .5em;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(75%, -50%); }
  .input-block-radio + .input-block-label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .input-block-radio + .input-block-label span {
      position: relative;
      top: 2px; }
    .input-block-radio + .input-block-label:before {
      content: '';
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 51%;
      display: inline-block;
      border: 1px solid #d3d1d1;
      cursor: pointer;
      margin-right: .5rem; }
  .input-block-radio:checked + .input-block-label:after {
    content: '';
    height: .5rem;
    width: .5rem;
    background: #3e93ba;
    position: absolute;
    display: block;
    top: 0;
    left: .4375rem;
    bottom: 0;
    margin: auto;
    border-radius: 51%; }
  .input-block-textarea {
    border-radius: 8px;
    min-height: 7.5rem;
    border: 1px solid #d3d1d1;
    padding: 1rem;
    width: 100%;
    resize: none;
    margin-top: 1rem;
    font-size: .875rem;
    line-height: 1.5rem; }

.input-block-input-wrapper--place-search > div {
  width: 100%; }

.custom-radio-icon {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 51%;
  display: inline-block;
  border: 1px solid #d3d1d1;
  position: relative;
  cursor: pointer; }
  .custom-radio-icon.checked:before {
    content: '';
    height: .5rem;
    width: .5rem;
    background: #3e93ba;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 51%; }

/**
* _buttons stylesheet 
*/
.btn, .btn-light-blue, .btn-empty, .btn-empty-cta, .btn-large, .btn-empty-grey, .btn-empty-red, .btn-cta, .btn-grey, .btn-red, .btn-empty-blue, .btn-white, .btn-action, .btn-secondary {
  border: none;
  border-radius: 5px;
  padding: .75em 2em;
  cursor: pointer;
  font-size: .85em; }
  .btn:disabled, .btn-light-blue:disabled, .btn-empty:disabled, .btn-empty-cta:disabled, .btn-large:disabled, .btn-empty-grey:disabled, .btn-empty-red:disabled, .btn-cta:disabled, .btn-grey:disabled, .btn-red:disabled, .btn-empty-blue:disabled, .btn-white:disabled, .btn-action:disabled, .btn-secondary:disabled {
    background-color: #f9f9f9;
    color: #767676; }
  .btn-light-blue {
    background: #ebf4f8;
    color: #767676;
    font-weight: 900; }
  .btn-empty, .btn-empty-cta, .btn-large {
    background-color: transparent;
    border: none;
    color: #454545;
    font-weight: 900;
    font-size: 1em; }
    .btn-empty-grey {
      background-color: white;
      border: 1px solid #d3d1d1;
      color: #272727; }
    .btn-empty-red {
      background-color: white;
      border: 1px solid red;
      color: #272727; }
    .btn-empty-cta {
      color: #3e93ba;
      font-weight: bold; }
  .btn-cta {
    border: none;
    background-color: #3e93ba;
    color: white; }
    .btn-cta:disabled {
      background-color: #ebf4f8;
      color: #454545;
      cursor: not-allowed; }
  .btn-grey {
    border: 1px solid #767676;
    color: #767676; }
  .btn-red {
    border: 1px solid #767676;
    color: red; }
  .btn-empty-blue {
    color: #3e93ba;
    border: 1px solid #3e93ba; }
  .btn-white {
    color: #272727;
    background-color: white; }
  .btn-grey, .btn-greyedBlue {
    background-color: #f7f7f7; }
  .btn-large {
    color: #3e93ba;
    font-weight: bold; }
  .btn-action {
    border: 1px solid #d3d1d1;
    color: #272727;
    font-size: .875rem;
    padding: .5rem 1.5rem;
    display: inline-flex;
    align-items: center;
    font-weight: 900; }
    .btn-action span {
      display: inline-block;
      margin-right: .5rem; }
  .btn-secondary {
    background-color: #ebf4f8;
    color: #454545;
    font-weight: 700; }

.btn-empty:focus, .btn-empty-cta:focus, .btn-large:focus {
  border: 2px solid blue; }

.address-string {
  margin-top: 1rem;
  padding-left: 2rem;
  position: relative; }
  .address-string i {
    position: absolute;
    top: .25rem;
    left: 0;
    color: #d3d1d1; }
  .address-string span {
    position: relative;
    top: 3px; }
  .address-string span {
    line-height: 1.5rem;
    display: inline-block;
    min-height: 1.5rem; }

/**
* _helpers stylesheet 
*/
.m-0:not(.m-0-px) {
  margin: 0em !important; }

.m-0-px {
  margin: 0px !important; }

.m-00 {
  margin: 0em !important; }

.mb-0:not(.mb-0-px) {
  margin-bottom: 0em !important; }

.mb-0-px {
  margin-bottom: 0px !important; }

.mb-00 {
  margin-bottom: 0em !important; }

.mt-0:not(.mt-0-px) {
  margin-top: 0em !important; }

.mt-0-px {
  margin-top: 0px !important; }

.mt-00 {
  margin-top: 0em !important; }

.ml-0:not(.ml-0-px) {
  margin-left: 0em !important; }

.ml-0-px {
  margin-left: 0px !important; }

.ml-00 {
  margin-left: 0em !important; }

.mr-0:not(.mr-0-px) {
  margin-right: 0em !important; }

.mr-0-px {
  margin-right: 0px !important; }

.mr-00 {
  margin-right: 0em !important; }

.p-0:not(.p-0-px) {
  padding: 0em !important; }

.p-0-px {
  padding: 0px !important; }

.p-00 {
  padding: 0em !important; }

.pb-0:not(.pb-0-px) {
  padding-bottom: 0em !important; }

.pb-0-px {
  padding-bottom: 0px !important; }

.pb-00 {
  padding-bottom: 0em !important; }

.pt-0:not(.pt-0-px) {
  padding-top: 0em !important; }

.pt-0-px {
  padding-top: 0px !important; }

.pt-00 {
  padding-top: 0em !important; }

.pl-0:not(.pl-0-px) {
  padding-left: 0em !important; }

.pl-0-px {
  padding-left: 0px !important; }

.pl-00 {
  padding-left: 0em !important; }

.pr-0:not(.pr-0-px) {
  padding-right: 0em !important; }

.pr-0-px {
  padding-right: 0px !important; }

.pr-00 {
  padding-right: 0em !important; }

.b-0:not(.b-0-px) {
  border: 0em !important; }

.b-0-px {
  border: 0px !important; }

.b-00 {
  border: 0em !important; }

.bb-0:not(.bb-0-px) {
  border-bottom: 0em !important; }

.bb-0-px {
  border-bottom: 0px !important; }

.bb-00 {
  border-bottom: 0em !important; }

.bt-0:not(.bt-0-px) {
  border-top: 0em !important; }

.bt-0-px {
  border-top: 0px !important; }

.bt-00 {
  border-top: 0em !important; }

.bl-0:not(.bl-0-px) {
  border-left: 0em !important; }

.bl-0-px {
  border-left: 0px !important; }

.bl-00 {
  border-left: 0em !important; }

.br-0:not(.br-0-px) {
  border-right: 0em !important; }

.br-0-px {
  border-right: 0px !important; }

.br-00 {
  border-right: 0em !important; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.m-1:not(.m-0-px) {
  margin: 1em !important; }

.m-1-px {
  margin: 1px !important; }

.m-01 {
  margin: 0.1em !important; }

.mb-1:not(.mb-0-px) {
  margin-bottom: 1em !important; }

.mb-1-px {
  margin-bottom: 1px !important; }

.mb-01 {
  margin-bottom: 0.1em !important; }

.mt-1:not(.mt-0-px) {
  margin-top: 1em !important; }

.mt-1-px {
  margin-top: 1px !important; }

.mt-01 {
  margin-top: 0.1em !important; }

.ml-1:not(.ml-0-px) {
  margin-left: 1em !important; }

.ml-1-px {
  margin-left: 1px !important; }

.ml-01 {
  margin-left: 0.1em !important; }

.mr-1:not(.mr-0-px) {
  margin-right: 1em !important; }

.mr-1-px {
  margin-right: 1px !important; }

.mr-01 {
  margin-right: 0.1em !important; }

.p-1:not(.p-0-px) {
  padding: 1em !important; }

.p-1-px {
  padding: 1px !important; }

.p-01 {
  padding: 0.1em !important; }

.pb-1:not(.pb-0-px) {
  padding-bottom: 1em !important; }

.pb-1-px {
  padding-bottom: 1px !important; }

.pb-01 {
  padding-bottom: 0.1em !important; }

.pt-1:not(.pt-0-px) {
  padding-top: 1em !important; }

.pt-1-px {
  padding-top: 1px !important; }

.pt-01 {
  padding-top: 0.1em !important; }

.pl-1:not(.pl-0-px) {
  padding-left: 1em !important; }

.pl-1-px {
  padding-left: 1px !important; }

.pl-01 {
  padding-left: 0.1em !important; }

.pr-1:not(.pr-0-px) {
  padding-right: 1em !important; }

.pr-1-px {
  padding-right: 1px !important; }

.pr-01 {
  padding-right: 0.1em !important; }

.b-1:not(.b-0-px) {
  border: 1em !important; }

.b-1-px {
  border: 1px !important; }

.b-01 {
  border: 0.1em !important; }

.bb-1:not(.bb-0-px) {
  border-bottom: 1em !important; }

.bb-1-px {
  border-bottom: 1px !important; }

.bb-01 {
  border-bottom: 0.1em !important; }

.bt-1:not(.bt-0-px) {
  border-top: 1em !important; }

.bt-1-px {
  border-top: 1px !important; }

.bt-01 {
  border-top: 0.1em !important; }

.bl-1:not(.bl-0-px) {
  border-left: 1em !important; }

.bl-1-px {
  border-left: 1px !important; }

.bl-01 {
  border-left: 0.1em !important; }

.br-1:not(.br-0-px) {
  border-right: 1em !important; }

.br-1-px {
  border-right: 1px !important; }

.br-01 {
  border-right: 0.1em !important; }

.w-15 {
  width: 15% !important; }

.w-20 {
  width: 20% !important; }

.m-2:not(.m-0-px) {
  margin: 2em !important; }

.m-2-px {
  margin: 2px !important; }

.m-02 {
  margin: 0.2em !important; }

.mb-2:not(.mb-0-px) {
  margin-bottom: 2em !important; }

.mb-2-px {
  margin-bottom: 2px !important; }

.mb-02 {
  margin-bottom: 0.2em !important; }

.mt-2:not(.mt-0-px) {
  margin-top: 2em !important; }

.mt-2-px {
  margin-top: 2px !important; }

.mt-02 {
  margin-top: 0.2em !important; }

.ml-2:not(.ml-0-px) {
  margin-left: 2em !important; }

.ml-2-px {
  margin-left: 2px !important; }

.ml-02 {
  margin-left: 0.2em !important; }

.mr-2:not(.mr-0-px) {
  margin-right: 2em !important; }

.mr-2-px {
  margin-right: 2px !important; }

.mr-02 {
  margin-right: 0.2em !important; }

.p-2:not(.p-0-px) {
  padding: 2em !important; }

.p-2-px {
  padding: 2px !important; }

.p-02 {
  padding: 0.2em !important; }

.pb-2:not(.pb-0-px) {
  padding-bottom: 2em !important; }

.pb-2-px {
  padding-bottom: 2px !important; }

.pb-02 {
  padding-bottom: 0.2em !important; }

.pt-2:not(.pt-0-px) {
  padding-top: 2em !important; }

.pt-2-px {
  padding-top: 2px !important; }

.pt-02 {
  padding-top: 0.2em !important; }

.pl-2:not(.pl-0-px) {
  padding-left: 2em !important; }

.pl-2-px {
  padding-left: 2px !important; }

.pl-02 {
  padding-left: 0.2em !important; }

.pr-2:not(.pr-0-px) {
  padding-right: 2em !important; }

.pr-2-px {
  padding-right: 2px !important; }

.pr-02 {
  padding-right: 0.2em !important; }

.b-2:not(.b-0-px) {
  border: 2em !important; }

.b-2-px {
  border: 2px !important; }

.b-02 {
  border: 0.2em !important; }

.bb-2:not(.bb-0-px) {
  border-bottom: 2em !important; }

.bb-2-px {
  border-bottom: 2px !important; }

.bb-02 {
  border-bottom: 0.2em !important; }

.bt-2:not(.bt-0-px) {
  border-top: 2em !important; }

.bt-2-px {
  border-top: 2px !important; }

.bt-02 {
  border-top: 0.2em !important; }

.bl-2:not(.bl-0-px) {
  border-left: 2em !important; }

.bl-2-px {
  border-left: 2px !important; }

.bl-02 {
  border-left: 0.2em !important; }

.br-2:not(.br-0-px) {
  border-right: 2em !important; }

.br-2-px {
  border-right: 2px !important; }

.br-02 {
  border-right: 0.2em !important; }

.w-25 {
  width: 25% !important; }

.w-30 {
  width: 30% !important; }

.m-3:not(.m-0-px) {
  margin: 3em !important; }

.m-3-px {
  margin: 3px !important; }

.m-03 {
  margin: 0.3em !important; }

.mb-3:not(.mb-0-px) {
  margin-bottom: 3em !important; }

.mb-3-px {
  margin-bottom: 3px !important; }

.mb-03 {
  margin-bottom: 0.3em !important; }

.mt-3:not(.mt-0-px) {
  margin-top: 3em !important; }

.mt-3-px {
  margin-top: 3px !important; }

.mt-03 {
  margin-top: 0.3em !important; }

.ml-3:not(.ml-0-px) {
  margin-left: 3em !important; }

.ml-3-px {
  margin-left: 3px !important; }

.ml-03 {
  margin-left: 0.3em !important; }

.mr-3:not(.mr-0-px) {
  margin-right: 3em !important; }

.mr-3-px {
  margin-right: 3px !important; }

.mr-03 {
  margin-right: 0.3em !important; }

.p-3:not(.p-0-px) {
  padding: 3em !important; }

.p-3-px {
  padding: 3px !important; }

.p-03 {
  padding: 0.3em !important; }

.pb-3:not(.pb-0-px) {
  padding-bottom: 3em !important; }

.pb-3-px {
  padding-bottom: 3px !important; }

.pb-03 {
  padding-bottom: 0.3em !important; }

.pt-3:not(.pt-0-px) {
  padding-top: 3em !important; }

.pt-3-px {
  padding-top: 3px !important; }

.pt-03 {
  padding-top: 0.3em !important; }

.pl-3:not(.pl-0-px) {
  padding-left: 3em !important; }

.pl-3-px {
  padding-left: 3px !important; }

.pl-03 {
  padding-left: 0.3em !important; }

.pr-3:not(.pr-0-px) {
  padding-right: 3em !important; }

.pr-3-px {
  padding-right: 3px !important; }

.pr-03 {
  padding-right: 0.3em !important; }

.b-3:not(.b-0-px) {
  border: 3em !important; }

.b-3-px {
  border: 3px !important; }

.b-03 {
  border: 0.3em !important; }

.bb-3:not(.bb-0-px) {
  border-bottom: 3em !important; }

.bb-3-px {
  border-bottom: 3px !important; }

.bb-03 {
  border-bottom: 0.3em !important; }

.bt-3:not(.bt-0-px) {
  border-top: 3em !important; }

.bt-3-px {
  border-top: 3px !important; }

.bt-03 {
  border-top: 0.3em !important; }

.bl-3:not(.bl-0-px) {
  border-left: 3em !important; }

.bl-3-px {
  border-left: 3px !important; }

.bl-03 {
  border-left: 0.3em !important; }

.br-3:not(.br-0-px) {
  border-right: 3em !important; }

.br-3-px {
  border-right: 3px !important; }

.br-03 {
  border-right: 0.3em !important; }

.w-35 {
  width: 35% !important; }

.w-40 {
  width: 40% !important; }

.m-4:not(.m-0-px) {
  margin: 4em !important; }

.m-4-px {
  margin: 4px !important; }

.m-04 {
  margin: 0.4em !important; }

.mb-4:not(.mb-0-px) {
  margin-bottom: 4em !important; }

.mb-4-px {
  margin-bottom: 4px !important; }

.mb-04 {
  margin-bottom: 0.4em !important; }

.mt-4:not(.mt-0-px) {
  margin-top: 4em !important; }

.mt-4-px {
  margin-top: 4px !important; }

.mt-04 {
  margin-top: 0.4em !important; }

.ml-4:not(.ml-0-px) {
  margin-left: 4em !important; }

.ml-4-px {
  margin-left: 4px !important; }

.ml-04 {
  margin-left: 0.4em !important; }

.mr-4:not(.mr-0-px) {
  margin-right: 4em !important; }

.mr-4-px {
  margin-right: 4px !important; }

.mr-04 {
  margin-right: 0.4em !important; }

.p-4:not(.p-0-px) {
  padding: 4em !important; }

.p-4-px {
  padding: 4px !important; }

.p-04 {
  padding: 0.4em !important; }

.pb-4:not(.pb-0-px) {
  padding-bottom: 4em !important; }

.pb-4-px {
  padding-bottom: 4px !important; }

.pb-04 {
  padding-bottom: 0.4em !important; }

.pt-4:not(.pt-0-px) {
  padding-top: 4em !important; }

.pt-4-px {
  padding-top: 4px !important; }

.pt-04 {
  padding-top: 0.4em !important; }

.pl-4:not(.pl-0-px) {
  padding-left: 4em !important; }

.pl-4-px {
  padding-left: 4px !important; }

.pl-04 {
  padding-left: 0.4em !important; }

.pr-4:not(.pr-0-px) {
  padding-right: 4em !important; }

.pr-4-px {
  padding-right: 4px !important; }

.pr-04 {
  padding-right: 0.4em !important; }

.b-4:not(.b-0-px) {
  border: 4em !important; }

.b-4-px {
  border: 4px !important; }

.b-04 {
  border: 0.4em !important; }

.bb-4:not(.bb-0-px) {
  border-bottom: 4em !important; }

.bb-4-px {
  border-bottom: 4px !important; }

.bb-04 {
  border-bottom: 0.4em !important; }

.bt-4:not(.bt-0-px) {
  border-top: 4em !important; }

.bt-4-px {
  border-top: 4px !important; }

.bt-04 {
  border-top: 0.4em !important; }

.bl-4:not(.bl-0-px) {
  border-left: 4em !important; }

.bl-4-px {
  border-left: 4px !important; }

.bl-04 {
  border-left: 0.4em !important; }

.br-4:not(.br-0-px) {
  border-right: 4em !important; }

.br-4-px {
  border-right: 4px !important; }

.br-04 {
  border-right: 0.4em !important; }

.w-45 {
  width: 45% !important; }

.w-50 {
  width: 50% !important; }

.m-5:not(.m-0-px) {
  margin: 5em !important; }

.m-5-px {
  margin: 5px !important; }

.m-05 {
  margin: 0.5em !important; }

.mb-5:not(.mb-0-px) {
  margin-bottom: 5em !important; }

.mb-5-px {
  margin-bottom: 5px !important; }

.mb-05 {
  margin-bottom: 0.5em !important; }

.mt-5:not(.mt-0-px) {
  margin-top: 5em !important; }

.mt-5-px {
  margin-top: 5px !important; }

.mt-05 {
  margin-top: 0.5em !important; }

.ml-5:not(.ml-0-px) {
  margin-left: 5em !important; }

.ml-5-px {
  margin-left: 5px !important; }

.ml-05 {
  margin-left: 0.5em !important; }

.mr-5:not(.mr-0-px) {
  margin-right: 5em !important; }

.mr-5-px {
  margin-right: 5px !important; }

.mr-05 {
  margin-right: 0.5em !important; }

.p-5:not(.p-0-px) {
  padding: 5em !important; }

.p-5-px {
  padding: 5px !important; }

.p-05 {
  padding: 0.5em !important; }

.pb-5:not(.pb-0-px) {
  padding-bottom: 5em !important; }

.pb-5-px {
  padding-bottom: 5px !important; }

.pb-05 {
  padding-bottom: 0.5em !important; }

.pt-5:not(.pt-0-px) {
  padding-top: 5em !important; }

.pt-5-px {
  padding-top: 5px !important; }

.pt-05 {
  padding-top: 0.5em !important; }

.pl-5:not(.pl-0-px) {
  padding-left: 5em !important; }

.pl-5-px {
  padding-left: 5px !important; }

.pl-05 {
  padding-left: 0.5em !important; }

.pr-5:not(.pr-0-px) {
  padding-right: 5em !important; }

.pr-5-px {
  padding-right: 5px !important; }

.pr-05 {
  padding-right: 0.5em !important; }

.b-5:not(.b-0-px) {
  border: 5em !important; }

.b-5-px {
  border: 5px !important; }

.b-05 {
  border: 0.5em !important; }

.bb-5:not(.bb-0-px) {
  border-bottom: 5em !important; }

.bb-5-px {
  border-bottom: 5px !important; }

.bb-05 {
  border-bottom: 0.5em !important; }

.bt-5:not(.bt-0-px) {
  border-top: 5em !important; }

.bt-5-px {
  border-top: 5px !important; }

.bt-05 {
  border-top: 0.5em !important; }

.bl-5:not(.bl-0-px) {
  border-left: 5em !important; }

.bl-5-px {
  border-left: 5px !important; }

.bl-05 {
  border-left: 0.5em !important; }

.br-5:not(.br-0-px) {
  border-right: 5em !important; }

.br-5-px {
  border-right: 5px !important; }

.br-05 {
  border-right: 0.5em !important; }

.w-55 {
  width: 55% !important; }

.w-60 {
  width: 60% !important; }

.m-6:not(.m-0-px) {
  margin: 6em !important; }

.m-6-px {
  margin: 6px !important; }

.m-06 {
  margin: 0.6em !important; }

.mb-6:not(.mb-0-px) {
  margin-bottom: 6em !important; }

.mb-6-px {
  margin-bottom: 6px !important; }

.mb-06 {
  margin-bottom: 0.6em !important; }

.mt-6:not(.mt-0-px) {
  margin-top: 6em !important; }

.mt-6-px {
  margin-top: 6px !important; }

.mt-06 {
  margin-top: 0.6em !important; }

.ml-6:not(.ml-0-px) {
  margin-left: 6em !important; }

.ml-6-px {
  margin-left: 6px !important; }

.ml-06 {
  margin-left: 0.6em !important; }

.mr-6:not(.mr-0-px) {
  margin-right: 6em !important; }

.mr-6-px {
  margin-right: 6px !important; }

.mr-06 {
  margin-right: 0.6em !important; }

.p-6:not(.p-0-px) {
  padding: 6em !important; }

.p-6-px {
  padding: 6px !important; }

.p-06 {
  padding: 0.6em !important; }

.pb-6:not(.pb-0-px) {
  padding-bottom: 6em !important; }

.pb-6-px {
  padding-bottom: 6px !important; }

.pb-06 {
  padding-bottom: 0.6em !important; }

.pt-6:not(.pt-0-px) {
  padding-top: 6em !important; }

.pt-6-px {
  padding-top: 6px !important; }

.pt-06 {
  padding-top: 0.6em !important; }

.pl-6:not(.pl-0-px) {
  padding-left: 6em !important; }

.pl-6-px {
  padding-left: 6px !important; }

.pl-06 {
  padding-left: 0.6em !important; }

.pr-6:not(.pr-0-px) {
  padding-right: 6em !important; }

.pr-6-px {
  padding-right: 6px !important; }

.pr-06 {
  padding-right: 0.6em !important; }

.b-6:not(.b-0-px) {
  border: 6em !important; }

.b-6-px {
  border: 6px !important; }

.b-06 {
  border: 0.6em !important; }

.bb-6:not(.bb-0-px) {
  border-bottom: 6em !important; }

.bb-6-px {
  border-bottom: 6px !important; }

.bb-06 {
  border-bottom: 0.6em !important; }

.bt-6:not(.bt-0-px) {
  border-top: 6em !important; }

.bt-6-px {
  border-top: 6px !important; }

.bt-06 {
  border-top: 0.6em !important; }

.bl-6:not(.bl-0-px) {
  border-left: 6em !important; }

.bl-6-px {
  border-left: 6px !important; }

.bl-06 {
  border-left: 0.6em !important; }

.br-6:not(.br-0-px) {
  border-right: 6em !important; }

.br-6-px {
  border-right: 6px !important; }

.br-06 {
  border-right: 0.6em !important; }

.w-65 {
  width: 65% !important; }

.w-70 {
  width: 70% !important; }

.m-7:not(.m-0-px) {
  margin: 7em !important; }

.m-7-px {
  margin: 7px !important; }

.m-07 {
  margin: 0.7em !important; }

.mb-7:not(.mb-0-px) {
  margin-bottom: 7em !important; }

.mb-7-px {
  margin-bottom: 7px !important; }

.mb-07 {
  margin-bottom: 0.7em !important; }

.mt-7:not(.mt-0-px) {
  margin-top: 7em !important; }

.mt-7-px {
  margin-top: 7px !important; }

.mt-07 {
  margin-top: 0.7em !important; }

.ml-7:not(.ml-0-px) {
  margin-left: 7em !important; }

.ml-7-px {
  margin-left: 7px !important; }

.ml-07 {
  margin-left: 0.7em !important; }

.mr-7:not(.mr-0-px) {
  margin-right: 7em !important; }

.mr-7-px {
  margin-right: 7px !important; }

.mr-07 {
  margin-right: 0.7em !important; }

.p-7:not(.p-0-px) {
  padding: 7em !important; }

.p-7-px {
  padding: 7px !important; }

.p-07 {
  padding: 0.7em !important; }

.pb-7:not(.pb-0-px) {
  padding-bottom: 7em !important; }

.pb-7-px {
  padding-bottom: 7px !important; }

.pb-07 {
  padding-bottom: 0.7em !important; }

.pt-7:not(.pt-0-px) {
  padding-top: 7em !important; }

.pt-7-px {
  padding-top: 7px !important; }

.pt-07 {
  padding-top: 0.7em !important; }

.pl-7:not(.pl-0-px) {
  padding-left: 7em !important; }

.pl-7-px {
  padding-left: 7px !important; }

.pl-07 {
  padding-left: 0.7em !important; }

.pr-7:not(.pr-0-px) {
  padding-right: 7em !important; }

.pr-7-px {
  padding-right: 7px !important; }

.pr-07 {
  padding-right: 0.7em !important; }

.b-7:not(.b-0-px) {
  border: 7em !important; }

.b-7-px {
  border: 7px !important; }

.b-07 {
  border: 0.7em !important; }

.bb-7:not(.bb-0-px) {
  border-bottom: 7em !important; }

.bb-7-px {
  border-bottom: 7px !important; }

.bb-07 {
  border-bottom: 0.7em !important; }

.bt-7:not(.bt-0-px) {
  border-top: 7em !important; }

.bt-7-px {
  border-top: 7px !important; }

.bt-07 {
  border-top: 0.7em !important; }

.bl-7:not(.bl-0-px) {
  border-left: 7em !important; }

.bl-7-px {
  border-left: 7px !important; }

.bl-07 {
  border-left: 0.7em !important; }

.br-7:not(.br-0-px) {
  border-right: 7em !important; }

.br-7-px {
  border-right: 7px !important; }

.br-07 {
  border-right: 0.7em !important; }

.w-75 {
  width: 75% !important; }

.w-80 {
  width: 80% !important; }

.m-8:not(.m-0-px) {
  margin: 8em !important; }

.m-8-px {
  margin: 8px !important; }

.m-08 {
  margin: 0.8em !important; }

.mb-8:not(.mb-0-px) {
  margin-bottom: 8em !important; }

.mb-8-px {
  margin-bottom: 8px !important; }

.mb-08 {
  margin-bottom: 0.8em !important; }

.mt-8:not(.mt-0-px) {
  margin-top: 8em !important; }

.mt-8-px {
  margin-top: 8px !important; }

.mt-08 {
  margin-top: 0.8em !important; }

.ml-8:not(.ml-0-px) {
  margin-left: 8em !important; }

.ml-8-px {
  margin-left: 8px !important; }

.ml-08 {
  margin-left: 0.8em !important; }

.mr-8:not(.mr-0-px) {
  margin-right: 8em !important; }

.mr-8-px {
  margin-right: 8px !important; }

.mr-08 {
  margin-right: 0.8em !important; }

.p-8:not(.p-0-px) {
  padding: 8em !important; }

.p-8-px {
  padding: 8px !important; }

.p-08 {
  padding: 0.8em !important; }

.pb-8:not(.pb-0-px) {
  padding-bottom: 8em !important; }

.pb-8-px {
  padding-bottom: 8px !important; }

.pb-08 {
  padding-bottom: 0.8em !important; }

.pt-8:not(.pt-0-px) {
  padding-top: 8em !important; }

.pt-8-px {
  padding-top: 8px !important; }

.pt-08 {
  padding-top: 0.8em !important; }

.pl-8:not(.pl-0-px) {
  padding-left: 8em !important; }

.pl-8-px {
  padding-left: 8px !important; }

.pl-08 {
  padding-left: 0.8em !important; }

.pr-8:not(.pr-0-px) {
  padding-right: 8em !important; }

.pr-8-px {
  padding-right: 8px !important; }

.pr-08 {
  padding-right: 0.8em !important; }

.b-8:not(.b-0-px) {
  border: 8em !important; }

.b-8-px {
  border: 8px !important; }

.b-08 {
  border: 0.8em !important; }

.bb-8:not(.bb-0-px) {
  border-bottom: 8em !important; }

.bb-8-px {
  border-bottom: 8px !important; }

.bb-08 {
  border-bottom: 0.8em !important; }

.bt-8:not(.bt-0-px) {
  border-top: 8em !important; }

.bt-8-px {
  border-top: 8px !important; }

.bt-08 {
  border-top: 0.8em !important; }

.bl-8:not(.bl-0-px) {
  border-left: 8em !important; }

.bl-8-px {
  border-left: 8px !important; }

.bl-08 {
  border-left: 0.8em !important; }

.br-8:not(.br-0-px) {
  border-right: 8em !important; }

.br-8-px {
  border-right: 8px !important; }

.br-08 {
  border-right: 0.8em !important; }

.w-85 {
  width: 85% !important; }

.w-90 {
  width: 90% !important; }

.m-9:not(.m-0-px) {
  margin: 9em !important; }

.m-9-px {
  margin: 9px !important; }

.m-09 {
  margin: 0.9em !important; }

.mb-9:not(.mb-0-px) {
  margin-bottom: 9em !important; }

.mb-9-px {
  margin-bottom: 9px !important; }

.mb-09 {
  margin-bottom: 0.9em !important; }

.mt-9:not(.mt-0-px) {
  margin-top: 9em !important; }

.mt-9-px {
  margin-top: 9px !important; }

.mt-09 {
  margin-top: 0.9em !important; }

.ml-9:not(.ml-0-px) {
  margin-left: 9em !important; }

.ml-9-px {
  margin-left: 9px !important; }

.ml-09 {
  margin-left: 0.9em !important; }

.mr-9:not(.mr-0-px) {
  margin-right: 9em !important; }

.mr-9-px {
  margin-right: 9px !important; }

.mr-09 {
  margin-right: 0.9em !important; }

.p-9:not(.p-0-px) {
  padding: 9em !important; }

.p-9-px {
  padding: 9px !important; }

.p-09 {
  padding: 0.9em !important; }

.pb-9:not(.pb-0-px) {
  padding-bottom: 9em !important; }

.pb-9-px {
  padding-bottom: 9px !important; }

.pb-09 {
  padding-bottom: 0.9em !important; }

.pt-9:not(.pt-0-px) {
  padding-top: 9em !important; }

.pt-9-px {
  padding-top: 9px !important; }

.pt-09 {
  padding-top: 0.9em !important; }

.pl-9:not(.pl-0-px) {
  padding-left: 9em !important; }

.pl-9-px {
  padding-left: 9px !important; }

.pl-09 {
  padding-left: 0.9em !important; }

.pr-9:not(.pr-0-px) {
  padding-right: 9em !important; }

.pr-9-px {
  padding-right: 9px !important; }

.pr-09 {
  padding-right: 0.9em !important; }

.b-9:not(.b-0-px) {
  border: 9em !important; }

.b-9-px {
  border: 9px !important; }

.b-09 {
  border: 0.9em !important; }

.bb-9:not(.bb-0-px) {
  border-bottom: 9em !important; }

.bb-9-px {
  border-bottom: 9px !important; }

.bb-09 {
  border-bottom: 0.9em !important; }

.bt-9:not(.bt-0-px) {
  border-top: 9em !important; }

.bt-9-px {
  border-top: 9px !important; }

.bt-09 {
  border-top: 0.9em !important; }

.bl-9:not(.bl-0-px) {
  border-left: 9em !important; }

.bl-9-px {
  border-left: 9px !important; }

.bl-09 {
  border-left: 0.9em !important; }

.br-9:not(.br-0-px) {
  border-right: 9em !important; }

.br-9-px {
  border-right: 9px !important; }

.br-09 {
  border-right: 0.9em !important; }

.w-95 {
  width: 95% !important; }

.w-100 {
  width: 100% !important; }

@media (max-width: 979px) {
  .Global-page-wrapper .Footer {
    display: none; } }

.Global-separator {
  margin: 1em 0;
  border-color: #f7f7f7;
  opacity: .5; }

.Global-requestInfo-btn {
  width: 100%;
  padding: 1em; }
  @media (max-width: 1023px) {
    .Global-requestInfo-btn {
      max-width: 20rem; } }

.Global-printinfo {
  display: none; }

@media (min-width: 1024px) {
  .Global-page {
    margin-top: 10px; } }

.Global-page.print .Global-back, .Global-page.print .Global-top-warning, .Global-page.print .Global-view-photos-btn, .Global-page.print .Global-share, .Global-page.print .Global-info--apartment-top, .Global-page.print .Global-download, .Global-page.print .Global-form-wrapper {
  display: none; }

.Global-page.print .Global-info {
  padding: 1rem 0;
  width: 100%; }
  .Global-page.print .Global-info--neighborhood {
    display: block;
    width: 500px; }

.Global-page.print .Global-widget {
  height: 260px; }

.Global-page.print .Global-container {
  margin-top: 0;
  padding: 0 1rem;
  display: block; }

.Global-page.print .Global-includes-container {
  width: 650px;
  flex-wrap: nowrap;
  justify-content: space-between; }

.Global-page.print .Global-include-single {
  flex: 0 0 100px;
  margin: 0;
  padding: .5rem; }
  .Global-page.print .Global-include-single__img {
    height: 30px; }
  .Global-page.print .Global-include-single__text {
    margin-top: .5rem; }

.Global-page.print .Global-info-name, .Global-page.print .Global-printinfo {
  display: block; }

.Global-page.print .Global-info-loc {
  padding: 0; }
  .Global-page.print .Global-info-loc i {
    position: relative;
    display: inline-block;
    padding-right: 15px;
    font-size: 0; }
    .Global-page.print .Global-info-loc i:before {
      display: block;
      height: 1rem;
      width: 1rem;
      font-size: 1rem;
      line-height: 1;
      position: relative;
      top: -10px; }

.Global-page.print .Global-info-apartments-container {
  margin: 0; }

.Global-page.print .Global-walkscore i,
.Global-page.print .Global-walkscore span {
  display: inline-block; }

.Global-page.print .Global-walkscore i {
  padding-right: 15px; }

.Global-page.print .Global-container__part {
  margin-left: 0; }

.Global-page.print .Global-map {
  display: none; }

.Global-top {
  position: relative;
  font-size: .75rem;
  line-height: 1.33; }
  @media (min-width: 1024px) {
    .Global-top-warning {
      position: absolute;
      left: 1rem;
      bottom: 1rem;
      border-radius: 6px;
      opacity: .75;
      background: #272727;
      padding: .5rem 1rem;
      color: #ffffff;
      font-size: .8em; } }
  @media (max-width: 1023px) {
    .Global-top-warning {
      text-align: center;
      margin: .5rem auto;
      color: #767676;
      max-width: 20rem; } }

.Global-greysection {
  background-color: #f6f6f6; }

.Global-return {
  position: absolute;
  top: .1rem;
  left: .1rem;
  color: #000;
  font-size: 1rem;
  font-weight: 900;
  vertical-align: middle; }
  @media print {
    .Global-return {
      display: none; } }

.Global-back:focus {
  outline: none;
  box-shadow: none; }

.Global-widget-wrapper {
  position: relative; }

@media (min-width: 1024px) {
  .Global-widget {
    height: 300px;
    display: grid;
    grid-template-columns: 6fr 5fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 2px;
    grid-column-gap: 2px;
    margin-bottom: 30px; } }

@media screen and (max-width: 1023px) {
  .Global-widget {
    height: 342px; }
    .Global-widget__item:not(:first-child) {
      display: none; }
    .Global-widget__item:first-child {
      height: 100%; } }

.Global-widget__item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  cursor: pointer; }
  .Global-widget__item:first-child {
    grid-row-start: 1;
    grid-row-end: 3; }

.Global-view-photos-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: .675rem;
  border-radius: 3px;
  font-weight: 900;
  cursor: pointer; }

@media (min-width: 980px) {
  .Global-container {
    display: flex;
    flex-wrap: nowrap; } }

.Global-container__part--left {
  flex: 1 1 100%;
  margin-bottom: 2.5rem; }

@media (min-width: 980px) {
  .Global-container__part--right {
    margin-left: 2rem; } }

@media (max-width: 979px) {
  .Global-container__part--right {
    display: block; } }

.Global-btn-padding {
  padding-left: 5px; }

.Global-info {
  padding: 1rem 0; }
  .Global-info-xs--rate-range {
    display: none; }
  @media (min-width: 980px) {
    .Global-info-xs {
      display: none; } }
  @media (max-width: 979px) {
    .Global-info-xs .Global-form-fields-container {
      border-top: none;
      margin-top: 0;
      padding-top: 0; } }
  .Global-info--top {
    padding-top: 0; }
  .Global-info + .Global-info {
    border-top: 3px solid #efefef; }
    @media print {
      .Global-info + .Global-info {
        border: none; } }
  .Global-info-name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: .5rem; }
  .Global-info-away {
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #767676;
    margin-top: .5rem;
    font-size: .75rem; }
  .Global-info-loc {
    margin-top: 1rem;
    padding-left: 2rem;
    position: relative; }
    .Global-info-loc i {
      position: absolute;
      top: .25rem;
      left: 0;
      color: #d3d1d1; }
    .Global-info-loc span {
      position: relative;
      top: 3px; }
  .Global-info-heading {
    font-size: 1.25rem;
    margin-bottom: .5rem; }
  .Global-info-aside {
    font-size: .875rem; }
  .Global-info-apartments-container {
    margin-top: 2.5rem;
    display: flex; }
    @media (max-width: 979px) {
      .Global-info-apartments-container {
        flex-wrap: wrap; } }
  .Global-info-type {
    text-align: center;
    padding: 0 2rem; }
    @media (max-width: 979px) {
      .Global-info-type {
        margin-bottom: 1rem;
        min-width: 150px;
        padding: 0; } }
    .Global-info-type__price {
      margin-top: 1rem;
      font-size: 1rem;
      line-height: 1.25rem; }
      .Global-info-type__price i {
        color: #3e93ba;
        display: inline-block;
        margin-right: .5rem; }
    .Global-info-type__type {
      margin-top: .5rem;
      font-size: 1rem; }
  .Global-info-text {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 1rem; }
  .Global-info-amenities {
    display: flex;
    max-width: 400px;
    flex-wrap: wrap;
    margin-top: 1rem; }

.Global-map {
  height: 350px;
  max-width: 100%;
  width: 100%;
  position: relative;
  margin-top: 1.5rem; }

.Global-includes-container {
  max-width: 100%;
  width: 100%;
  display: flex;
  margin-top: 2.5rem;
  flex-wrap: wrap; }

.Global-include-single {
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 132px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: .5em 1em;
  text-align: center; }
  .Global-include-single__img {
    height: 60px; }
  .Global-include-single__text {
    font-size: .625rem;
    font-weight: 900;
    margin-top: 1.1rem;
    letter-spacing: .83px;
    text-transform: uppercase; }

.Global-amenity {
  font-size: 1rem;
  flex: 0 0 50%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .Global-amenity__img {
    display: inline-block;
    height: 24px;
    margin-right: .5em; }
  .Global-amenity i {
    color: #3e93ba;
    margin-right: .5rem; }

.Global-walkscore {
  font-size: .875rem;
  margin-bottom: 1.75rem; }
  .Global-walkscore i {
    color: #3e93ba;
    margin-right: .5rem; }

.Global-download {
  margin-top: 2rem; }

@media (min-width: 980px) {
  .Global-form-wrapper {
    background: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    padding: 1.5rem;
    width: 444px; } }

.Global-form-price {
  font-size: 1rem; }
  .Global-form-price b {
    font-size: 1.25rem; }

.Global-form-rating {
  margin-top: .5rem; }
  .Global-form-rating i {
    color: #3e93ba; }
    .Global-form-rating i:first-child {
      margin-left: 0; }

.Global-form-fields-container {
  border-top: 1px solid #efefef;
  margin-top: 0rem;
  padding-top: 1.5rem; }

.Global-form .input-block {
  margin: 1rem 0 0; }
  .Global-form .input-block-title {
    font-weight: 900;
    margin-bottom: .5rem;
    font-size: .75rem; }

.Global-form-submit {
  display: block;
  width: 100%;
  margin-top: 1.5rem;
  line-height: 3rem;
  font-size: .875rem;
  padding: 0;
  border-radius: 8px;
  text-align: center; }

.Global-form-warning {
  margin-top: 1rem;
  font-size: .625rem;
  font-weight: 900;
  letter-spacing: .83px;
  text-transform: uppercase;
  text-align: center; }

.Global-calendar {
  position: relative; }
  @media (max-width: 3999px) {
    .Global-calendar .input-block-label {
      padding: 0; }
      .Global-calendar .input-block-label--calendar {
        width: 100%; }
      .Global-calendar .input-block-label:before {
        display: none; } }
  @media (max-width: 3999px) {
    .Global-calendar .label-lg-container {
      display: none; } }
  @media (min-width: 4000px) {
    .Global-calendar .label-xs-container {
      display: none; } }
  @media (max-width: 3999px) {
    .Global-calendar .label-xs-container {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .Global-calendar .label-xs-container__item {
        line-height: 2.875rem;
        border: 1px solid #ccc;
        border-radius: 8px;
        position: relative;
        padding-left: 2.75rem;
        padding-left: 2.5rem;
        flex: 0 0 48%;
        display: block;
        font-size: .875rem;
        min-height: 2.5rem;
        color: #272727; }
        .Global-calendar .label-xs-container__item:before {
          position: absolute;
          font-size: 1rem;
          color: #d3d1d1;
          top: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          width: 1.5rem;
          height: 1.5rem;
          align-items: center;
          left: .75rem; } }
  @media (min-width: 4000px) {
    .Global-calendar > .input-block-titles-xs {
      display: none; } }
  @media (max-width: 3999px) {
    .Global-calendar > .input-block-titles-xs {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .Global-calendar > .input-block-titles-xs .input-block-title {
        flex: 0 0 48%;
        font-size: .75rem; } }
  @media (max-width: 3999px) {
    .Global-calendar > .input-block-title-lg {
      display: none; } }
  .Global-calendar .calendar-cover {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    border-radius: 0 0 8px 8px !important;
    background-color: white;
    overflow: hidden; }
    @media (min-width: 4000px) {
      .Global-calendar .calendar-cover {
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
        transform: translateY(98%);
        border-top: 1px solid #f9f9f9; } }
    @media (max-width: 3999px) {
      .Global-calendar .calendar-cover {
        width: 100%;
        top: 98%; } }
  .Global-calendar .visualController:checked ~ .calendar-cover {
    display: block; }
  @media (max-width: 3999px) {
    .Global-calendar .visualController:checked ~ .input-block-label .label-xs-container__item {
      flex: 0 0 50%; }
      .Global-calendar .visualController:checked ~ .input-block-label .label-xs-container__item:first-child {
        border-radius: 8px 0 0 0;
        border-right: 0; }
      .Global-calendar .visualController:checked ~ .input-block-label .label-xs-container__item:last-child {
        border-radius: 0 8px 0 0; } }
  .Global-calendar .label-xs-container__item {
    line-height: 2.375rem; }
  .Global-calendar .Calendar {
    border: 1px solid #ccc;
    overflow: hidden; }
    .Global-calendar .Calendar-footer {
      padding: 1rem; }
  .Global-calendar .rdr-Calendar {
    border: none !important; }
  .Global-calendar .calendar-cover {
    bottom: auto; }

.Global-select .Select-title {
  display: none; }

.Global-select .Select-label-container {
  width: 100%; }

.Global-cancellation-btn {
  color: #3e93ba;
  display: inline-block;
  margin-top: .5rem;
  font-size: .875rem;
  text-decoration: underline;
  cursor: pointer; }

.Global-booking-info {
  margin-top: 1.5rem; }
  .Global-booking-info-empty {
    text-align: center;
    font-size: .875rem;
    max-width: 10.5rem;
    margin: auto;
    padding: 1.5rem 0; }

.Global-booking-item {
  display: flex;
  justify-content: space-between;
  font-size: .875rem; }
  .Global-booking-item + .Global-booking-item {
    margin-top: .5rem; }
  .Global-booking-item__value {
    font-weight: 900; }

@media (min-width: 1024px) {
  .Global-footer {
    display: none; } }

@media (max-width: 1023px) {
  .Global-footer {
    border-top: 1px solid #efefef;
    padding: 1rem .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99;
    background-color: white;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14); }
    .Global-footer__right .btn-cta {
      font-size: 1rem;
      padding: 1rem 2rem; }
      .Global-footer__right .btn-cta i {
        margin-right: .5rem; } }

.Cancellation-policy-modal {
  padding: 1.5rem; }
  .Cancellation-policy-modal-heading {
    margin: 0 0 1.5rem;
    font-size: 1rem; }

.Cancellation-policy-wrapper .Modal-window {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14); }
  @media (min-width: 620px) {
    .Cancellation-policy-wrapper .Modal-window {
      width: 40%; } }
  .Cancellation-policy-wrapper .Modal-window-header {
    padding: 0;
    position: relative; }
    .Cancellation-policy-wrapper .Modal-window-header-icon-close {
      position: absolute;
      right: 1rem;
      top: 1rem; }

.Customize-policy-wrapper .Modal-window {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14); }
  @media (min-width: 620px) {
    .Customize-policy-wrapper .Modal-window {
      width: 40%; } }
  @media (min-width: 820px) {
    .Customize-policy-wrapper .Modal-window {
      width: 50%; } }
  .Customize-policy-wrapper .Modal-window-header {
    padding: 0;
    position: relative; }
    .Customize-policy-wrapper .Modal-window-header-icon-close {
      position: absolute;
      right: 1rem;
      top: 1rem; }

.StarRating-policy-modal {
  padding: 1.5rem; }
  .StarRating-policy-modal-heading {
    margin: 0 0 1.5rem;
    font-size: 1rem; }

.StarRating-policy-wrapper .Modal-window {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14); }
  @media (min-width: 620px) {
    .StarRating-policy-wrapper .Modal-window {
      width: 400px; } }
  @media (min-width: 820px) {
    .StarRating-policy-wrapper .Modal-window {
      width: 80%; } }
  .StarRating-policy-wrapper .Modal-window-header {
    padding: 0;
    position: relative; }
    .StarRating-policy-wrapper .Modal-window-header-icon-close {
      position: absolute;
      right: 1rem;
      top: 1rem; }

.btn-action {
  margin-left: 7px; }

.sticky {
  position: sticky;
  top: 10%;
  margin-bottom: 100px; }
  @media (max-width: 1023px) {
    .sticky {
      padding-bottom: 2.5rem; } }

@media print {
  * {
    -webkit-print-color-adjust: exact; }
  .Global-top {
    padding-top: 15px; }
  .Global-page {
    position: relative; }
  .Global-container {
    margin-top: 0; }
  .single-page-header,
  .Global-view-photos-btn,
  .Global-footer__right,
  .btn-action,
  .Global-footer,
  .Global-info-xs,
  .Global-info--apartment-top,
  .optanon-alert-box-wrapper {
    display: none !important; }
  .Global-widget {
    height: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr; }
    .Global-widget__item {
      display: block !important;
      height: 100%; }
  .Global-info-amenities {
    max-width: none; }
  .Global-info--neighborhood {
    page-break-inside: avoid; }
  .Global-amenity {
    flex: 0 0 32%; }
  .aco-show-audio-btn {
    display: none; } }

.App {
  text-align: center; }

.App-logo {
  height: 40vmin;
  pointer-events: none; }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear; } }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.smallform {
  width: 50%;
  margin-bottom: 10px; }

.GlobalPrices-container__part--right {
  background-color: #f9f9f9; }
  @media (min-width: 980px) {
    .GlobalPrices-container__part--right {
      flex: 0 0 33%; } }
  @media (max-width: 979px) {
    .GlobalPrices-container__part--right {
      display: none; } }

.GlobalPrices-info {
  padding: 2.5rem 0; }
  @media (min-width: 980px) {
    .GlobalPrices-info-xs {
      display: none; } }
  @media (max-width: 979px) {
    .GlobalPrices-info-xs .GlobalPrices-form-fields-container {
      border-top: none;
      margin-top: 0;
      padding-top: 0; } }
  .GlobalPrices-info--top {
    padding-top: 0; }
  .GlobalPrices-info + .GlobalPrices-info {
    border-top: 1px solid #efefef; }
  .GlobalPrices-info-name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: .5rem; }
  .GlobalPrices-info-away {
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #767676;
    margin-top: .5rem;
    font-size: .75rem; }
  .GlobalPrices-info-loc {
    margin-top: 1rem;
    padding-left: 2rem;
    position: relative;
    font-size: 0.875rem; }
    .GlobalPrices-info-loc i {
      position: absolute;
      top: .25rem;
      left: 0;
      color: #d3d1d1; }
    .GlobalPrices-info-loc span {
      position: relative;
      top: 3px; }
  .GlobalPrices-info-heading {
    font-size: 1.25rem;
    margin-bottom: .5rem; }
  .GlobalPrices-info-aside {
    font-size: .875rem; }
    .GlobalPrices-info-aside > .icon-bold-arrow-right {
      font-size: .7rem;
      color: #96b42b;
      padding: 0 3rem; }
  .GlobalPrices-info-apartments-container {
    margin-top: 2.5rem;
    display: flex; }
    @media (max-width: 979px) {
      .GlobalPrices-info-apartments-container {
        flex-wrap: wrap; } }
  .GlobalPrices-info-type {
    text-align: center;
    padding: 0 2rem; }
    @media (max-width: 979px) {
      .GlobalPrices-info-type {
        margin-bottom: 1rem;
        min-width: 150px;
        padding: 0; } }
    .GlobalPrices-info-type__price {
      margin-top: 1rem;
      font-size: 1rem;
      line-height: 1.25rem; }
      .GlobalPrices-info-type__price i {
        color: #3e93ba;
        display: inline-block;
        margin-right: .5rem; }
    .GlobalPrices-info-type__type {
      margin-top: .5rem;
      font-size: 1rem; }
  .GlobalPrices-info-text {
    font-size: 1rem;
    line-height: 1.5rem; }

.GlobalPrices-map {
  height: 244px;
  max-width: 400px;
  width: 100%;
  position: relative;
  margin-top: 1.5rem; }

.GlobalPrices-walkscore {
  font-size: .875rem;
  margin-bottom: 1.75rem; }
  .GlobalPrices-walkscore i {
    color: #3e93ba;
    margin-right: .5rem; }

.GlobalPrices-download {
  margin-top: 2rem; }

@media (min-width: 980px) {
  .GlobalPrices-form-wrapper {
    background: #f9f9f9;
    padding: 0;
    min-height: 100%; } }

@media (min-width: 980px) {
  .GlobalPrices-form {
    padding: 1rem 6rem 1rem 2rem; } }

@media (max-width: 979px) {
  .GlobalPrices-form {
    padding: 1.5rem; } }

.GlobalPrices-form-price {
  font-size: 1rem; }
  .GlobalPrices-form-price b {
    font-size: 1.25rem; }

.GlobalPrices-form-rating {
  margin-top: .5rem; }
  .GlobalPrices-form-rating i {
    color: #3e93ba; }
    .GlobalPrices-form-rating i:first-child {
      margin-left: 0; }

.GlobalPrices-form-fields-container {
  border-top: 1px solid #efefef;
  margin-top: 1.5rem;
  padding-top: 1.5rem; }

.GlobalPrices-form .input-block {
  margin: 1rem 0 0; }
  .GlobalPrices-form .input-block-title {
    font-weight: 900;
    margin-bottom: .5rem;
    font-size: .75rem; }

.GlobalPrices-form-submit {
  display: block;
  width: 100%;
  margin-top: 1.5rem;
  line-height: 3rem;
  font-size: .875rem;
  padding: 0;
  border-radius: 8px; }

.GlobalPrices-form-warning {
  margin-top: 1rem;
  font-size: .625rem;
  font-weight: 900;
  letter-spacing: .83px;
  text-transform: uppercase;
  text-align: center; }

.GlobalPrices-calendar {
  position: relative; }
  @media (max-width: 3999px) {
    .GlobalPrices-calendar .input-block-label {
      padding: 0; }
      .GlobalPrices-calendar .input-block-label--calendar {
        width: 100%; }
      .GlobalPrices-calendar .input-block-label:before {
        display: none; } }
  @media (max-width: 3999px) {
    .GlobalPrices-calendar .label-lg-container {
      display: none; } }
  @media (min-width: 4000px) {
    .GlobalPrices-calendar .label-xs-container {
      display: none; } }
  @media (max-width: 3999px) {
    .GlobalPrices-calendar .label-xs-container {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .GlobalPrices-calendar .label-xs-container__item {
        line-height: 2.875rem;
        border: 1px solid #ccc;
        border-radius: 8px;
        position: relative;
        padding-left: 2.75rem;
        padding-left: 2.5rem;
        flex: 0 0 48%;
        display: block;
        font-size: .875rem;
        min-height: 2.5rem;
        color: #272727; }
        .GlobalPrices-calendar .label-xs-container__item:before {
          position: absolute;
          font-size: 1rem;
          color: #d3d1d1;
          top: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          width: 1.5rem;
          height: 1.5rem;
          align-items: center;
          left: .75rem; } }
  @media (min-width: 4000px) {
    .GlobalPrices-calendar > .input-block-titles-xs {
      display: none; } }
  @media (max-width: 3999px) {
    .GlobalPrices-calendar > .input-block-titles-xs {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .GlobalPrices-calendar > .input-block-titles-xs .input-block-title {
        flex: 0 0 48%;
        font-size: .75rem; } }
  @media (max-width: 3999px) {
    .GlobalPrices-calendar > .input-block-title-lg {
      display: none; } }
  .GlobalPrices-calendar .calendar-cover {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    border-radius: 0 0 8px 8px !important;
    background-color: white;
    overflow: hidden; }
    @media (min-width: 4000px) {
      .GlobalPrices-calendar .calendar-cover {
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
        transform: translateY(98%);
        border-top: 1px solid #f9f9f9; } }
    @media (max-width: 3999px) {
      .GlobalPrices-calendar .calendar-cover {
        width: 100%;
        top: 98%; } }
  .GlobalPrices-calendar .visualController:checked ~ .calendar-cover {
    display: block; }
  @media (max-width: 3999px) {
    .GlobalPrices-calendar .visualController:checked ~ .input-block-label .label-xs-container__item {
      flex: 0 0 50%; }
      .GlobalPrices-calendar .visualController:checked ~ .input-block-label .label-xs-container__item:first-child {
        border-radius: 8px 0 0 0;
        border-right: 0; }
      .GlobalPrices-calendar .visualController:checked ~ .input-block-label .label-xs-container__item:last-child {
        border-radius: 0 8px 0 0; } }
  .GlobalPrices-calendar .label-xs-container__item {
    line-height: 2.375rem; }
  .GlobalPrices-calendar .Calendar {
    border: 1px solid #ccc;
    overflow: hidden; }
    .GlobalPrices-calendar .Calendar-footer {
      padding: 1rem; }
  .GlobalPrices-calendar .rdr-Calendar {
    border: none !important; }

.GlobalPrices-select .Select-title {
  display: none; }

.GlobalPrices-select .Select-label-container {
  width: 100%; }

.GlobalPrices-cancellation-btn {
  color: #3e93ba;
  display: inline-block;
  margin-top: .5rem;
  font-size: .875rem;
  text-decoration: underline;
  cursor: pointer; }

.GlobalPrices-booking-info {
  margin-top: 1.5rem; }

.GlobalPrices-booking-item {
  display: flex;
  justify-content: space-between;
  font-size: .875rem;
  margin-top: .5rem; }
  .GlobalPrices-booking-item + .GlobalPrices-booking-item {
    margin-top: 1rem; }
  .GlobalPrices-booking-item__value {
    font-weight: 900; }

@media (min-width: 1024px) {
  .GlobalPrices-footer {
    display: none; } }

@media (max-width: 1023px) {
  .GlobalPrices-footer {
    border-top: 1px solid #efefef;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap; }
    .GlobalPrices-footer__right button {
      font-size: 1rem;
      padding: 1rem 2rem; }
      .GlobalPrices-footer__right button i {
        margin-right: .5rem; } }

.GlobalPrices-customize {
  width: 50%;
  margin-left: auto;
  margin-right: auto; }

.Cancellation-policy-modal {
  padding: 1.5rem; }
  .Cancellation-policy-modal-heading {
    margin: 0 0 1.5rem;
    font-size: 1rem; }
    .Cancellation-policy-modal-heading-amenities {
      font-size: 0.85rem; }

.Cancellation-policy-wrapper .Modal-window {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14); }
  @media (min-width: 620px) {
    .Cancellation-policy-wrapper .Modal-window {
      width: 400px; } }
  @media (min-width: 820px) {
    .Cancellation-policy-wrapper .Modal-window {
      width: 600px; } }
  .Cancellation-policy-wrapper .Modal-window-header {
    padding: 0;
    position: relative; }
    .Cancellation-policy-wrapper .Modal-window-header-icon-close {
      position: absolute;
      right: 1rem;
      top: 1rem; }

.border-top-midlightgrey {
  border-top: 1px solid #efefef; }

.border-top-lightgrey {
  border-top: 1px solid #d3d1d1; }

.property-png-PH {
  background-image: url("../img/drafts/property.png");
  height: 216px; }

.font-small {
  font-size: 0.875rem; }

.addOns {
  padding-left: 0.5rem;
  padding-top: 0.5rem; }

.modal-footer {
  background-color: #f9f9f9;
  border-top: 1px solid #767676;
  border-radius: 0 0 8px 8px;
  padding: 1rem;
  display: flex;
  justify-content: space-between; }

.modal-ctrl {
  cursor: pointer;
  font-weight: bolder; }
  .modal-ctrl.clear {
    color: #272727; }
  .modal-ctrl.apply {
    color: #3e93ba; }

.tooltip-container {
  position: relative;
  display: inline-block; }

.tooltip-container .tooltip {
  visibility: hidden;
  width: 300px;
  background-color: #454545;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: .5rem;
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: 50%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.875rem; }

.tooltip-container .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: #454545 transparent transparent transparent; }

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1; }

@media (min-width: 1024px) {
  .GlobalCustomize-page {
    padding-top: 85px; } }

.GlobalCustomize-top {
  position: relative;
  font-size: .75rem;
  line-height: 1.33; }
  @media (min-width: 1024px) {
    .GlobalCustomize-top-warning {
      position: absolute;
      left: 1rem;
      bottom: 1rem;
      border-radius: 6px;
      opacity: .75;
      background: #272727;
      padding: .5rem 1rem;
      color: #ffffff; } }
  @media (max-width: 1023px) {
    .GlobalCustomize-top-warning {
      text-align: center;
      margin: .5rem auto;
      color: #767676;
      max-width: 20rem; } }

.GlobalCustomize-back {
  position: absolute;
  top: 2rem;
  left: 2rem;
  color: #fff;
  font-size: .875rem;
  font-weight: 900; }

.GlobalCustomize-widget-wrapper {
  position: relative; }

@media (min-width: 1024px) {
  .GlobalCustomize-widget {
    height: 576px;
    display: grid;
    grid-template-columns: 8fr 6fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 2px;
    grid-column-gap: 2px; } }

@media (max-width: 1023px) {
  .GlobalCustomize-widget {
    height: 342px; }
    .GlobalCustomize-widget__item:not(:first-child) {
      display: none; }
    .GlobalCustomize-widget__item:first-child {
      height: 100%; } }

.GlobalCustomize-widget__item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  cursor: pointer; }
  .GlobalCustomize-widget__item:first-child {
    grid-row-start: 1;
    grid-row-end: 3; }

.GlobalCustomize-view-photos-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: .875rem;
  border-radius: 8px;
  padding: 1rem 1.75rem;
  font-weight: 900;
  cursor: pointer; }

.GlobalCustomize-container {
  display: flex;
  flex-wrap: nowrap; }
  @media (min-width: 980px) {
    .GlobalCustomize-container {
      flex-direction: row; } }
  @media (max-width: 979px) {
    .GlobalCustomize-container {
      flex-direction: column; } }
  .GlobalCustomize-container__part--left {
    flex: 1 1 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%; }

.GlobalCustomize-info {
  padding: 2.5rem 0; }
  @media (min-width: 980px) {
    .GlobalCustomize-info-xs {
      display: none; } }
  @media (max-width: 979px) {
    .GlobalCustomize-info-xs {
      padding-top: 1rem !important; }
      .GlobalCustomize-info-xs .GlobalCustomize-form-fields-container {
        border-top: none;
        margin-top: 0;
        padding-top: 0; } }
  .GlobalCustomize-info--top {
    padding-top: 0; }
  .GlobalCustomize-info + .GlobalCustomize-info {
    border-top: 1px solid #efefef; }
  .GlobalCustomize-info-name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: .5rem; }
  .GlobalCustomize-info-away {
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #767676;
    margin-top: .5rem;
    font-size: .75rem; }
  .GlobalCustomize-info-loc {
    margin-top: 1rem;
    padding-left: 2rem;
    position: relative;
    font-size: 0.875rem; }
    .GlobalCustomize-info-loc i {
      position: absolute;
      top: .25rem;
      left: 0;
      color: #d3d1d1; }
    .GlobalCustomize-info-loc span {
      position: relative;
      top: 3px; }
  .GlobalCustomize-info-heading {
    font-size: 1.25rem;
    margin-bottom: .5rem; }
  .GlobalCustomize-info-aside {
    font-size: .875rem; }
    .GlobalCustomize-info-aside > .icon-bold-arrow-right {
      font-size: .7rem;
      color: #96b42b;
      padding: 0 3rem; }
  .GlobalCustomize-info-apartments-container {
    margin-top: 2.5rem;
    display: flex; }
    @media (max-width: 979px) {
      .GlobalCustomize-info-apartments-container {
        flex-wrap: wrap; } }
  .GlobalCustomize-info-type {
    text-align: center;
    padding: 0 2rem; }
    @media (max-width: 979px) {
      .GlobalCustomize-info-type {
        margin-bottom: 1rem;
        min-width: 150px;
        padding: 0; } }
    .GlobalCustomize-info-type__price {
      margin-top: 1rem;
      font-size: 1rem;
      line-height: 1.25rem; }
      .GlobalCustomize-info-type__price i {
        color: #3e93ba;
        display: inline-block;
        margin-right: .5rem; }
    .GlobalCustomize-info-type__type {
      margin-top: .5rem;
      font-size: 1rem; }
  .GlobalCustomize-info-text {
    font-size: 1rem;
    line-height: 1.5rem; }
  .GlobalCustomize-info-amenities {
    max-width: 400px;
    margin-top: 1.5rem; }

.GlobalCustomize-map {
  height: 244px;
  max-width: 400px;
  width: 100%;
  position: relative;
  margin-top: 1.5rem; }

.GlobalCustomize-includes-container {
  display: flex;
  margin-top: 2.5rem;
  flex-wrap: wrap; }

.GlobalCustomize-include-single {
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 152px;
  height: 100px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .GlobalCustomize-include-single__text {
    font-size: .625rem;
    font-weight: 900;
    margin-top: 1.1rem;
    letter-spacing: .83px; }

.GlobalCustomize-amenity {
  font-size: 0.85rem;
  margin-bottom: 1.5rem; }
  .GlobalCustomize-amenity i {
    font-size: 0.5rem;
    color: #3e93ba;
    margin-right: .5rem;
    margin-left: .5rem; }

.GlobalCustomize-walkscore {
  font-size: .875rem;
  margin-bottom: 1.75rem; }
  .GlobalCustomize-walkscore i {
    color: #3e93ba;
    margin-right: .5rem; }

.GlobalCustomize-download {
  margin-top: 2rem; }

@media (min-width: 980px) {
  .GlobalCustomize-form-wrapper {
    background: #f9f9f9;
    padding: 0;
    min-height: 100%; } }

.GlobalCustomize-calendar {
  position: relative; }
  @media (max-width: 3999px) {
    .GlobalCustomize-calendar .input-block-label {
      padding: 0; }
      .GlobalCustomize-calendar .input-block-label--calendar {
        width: 100%; }
      .GlobalCustomize-calendar .input-block-label:before {
        display: none; } }
  @media (max-width: 3999px) {
    .GlobalCustomize-calendar .label-lg-container {
      display: none; } }
  @media (min-width: 4000px) {
    .GlobalCustomize-calendar .label-xs-container {
      display: none; } }
  @media (max-width: 3999px) {
    .GlobalCustomize-calendar .label-xs-container {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .GlobalCustomize-calendar .label-xs-container__item {
        line-height: 2.875rem;
        border: 1px solid #ccc;
        border-radius: 8px;
        position: relative;
        padding-left: 2.75rem;
        padding-left: 2.5rem;
        flex: 0 0 48%;
        display: block;
        font-size: .875rem;
        min-height: 2.5rem;
        color: #272727; }
        .GlobalCustomize-calendar .label-xs-container__item:before {
          position: absolute;
          font-size: 1rem;
          color: #d3d1d1;
          top: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          width: 1.5rem;
          height: 1.5rem;
          align-items: center;
          left: .75rem; } }
  @media (min-width: 4000px) {
    .GlobalCustomize-calendar > .input-block-titles-xs {
      display: none; } }
  @media (max-width: 3999px) {
    .GlobalCustomize-calendar > .input-block-titles-xs {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .GlobalCustomize-calendar > .input-block-titles-xs .input-block-title {
        flex: 0 0 48%;
        font-size: .75rem; } }
  @media (max-width: 3999px) {
    .GlobalCustomize-calendar > .input-block-title-lg {
      display: none; } }
  .GlobalCustomize-calendar .calendar-cover {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    border-radius: 0 0 8px 8px !important;
    background-color: white;
    overflow: hidden; }
    @media (min-width: 4000px) {
      .GlobalCustomize-calendar .calendar-cover {
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
        transform: translateY(98%);
        border-top: 1px solid #f9f9f9; } }
    @media (max-width: 3999px) {
      .GlobalCustomize-calendar .calendar-cover {
        width: 100%;
        top: 98%; } }
  .GlobalCustomize-calendar .visualController:checked ~ .calendar-cover {
    display: block; }
  @media (max-width: 3999px) {
    .GlobalCustomize-calendar .visualController:checked ~ .input-block-label .label-xs-container__item {
      flex: 0 0 50%; }
      .GlobalCustomize-calendar .visualController:checked ~ .input-block-label .label-xs-container__item:first-child {
        border-radius: 8px 0 0 0;
        border-right: 0; }
      .GlobalCustomize-calendar .visualController:checked ~ .input-block-label .label-xs-container__item:last-child {
        border-radius: 0 8px 0 0; } }
  .GlobalCustomize-calendar .label-xs-container__item {
    line-height: 2.375rem; }
  .GlobalCustomize-calendar .Calendar {
    border: 1px solid #ccc;
    overflow: hidden; }
    .GlobalCustomize-calendar .Calendar-footer {
      padding: 1rem; }
  .GlobalCustomize-calendar .rdr-Calendar {
    border: none !important; }

.GlobalCustomize-select .Select-title {
  display: none; }

.GlobalCustomize-select .Select-label-container {
  width: 100%; }

.GlobalCustomize-cancellation-btn {
  color: #3e93ba;
  display: inline-block;
  margin-top: .5rem;
  font-size: .875rem;
  text-decoration: underline;
  cursor: pointer; }

.GlobalCustomize-booking-info {
  margin-top: 1.5rem; }

.GlobalCustomize-booking-item {
  display: flex;
  justify-content: space-between;
  font-size: .875rem;
  margin-top: .5rem; }
  .GlobalCustomize-booking-item + .GlobalCustomize-Global-item {
    margin-top: 1rem; }
  .GlobalCustomize-booking-item__value {
    font-weight: 900; }

@media (min-width: 1024px) {
  .GlobalCustomize-footer {
    display: none; } }

@media (max-width: 1023px) {
  .GlobalCustomize-footer {
    border-top: 1px solid #efefef;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap; }
    .GlobalCustomize-footer__right button {
      font-size: 1rem;
      padding: 1rem 2rem; }
      .GlobalCustomize-footer__right button i {
        margin-right: .5rem; } }

.GlobalCustomize-customize {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  flex: 1 0 auto; }

.Cancellation-policy-modal {
  padding: 1.5rem; }
  .Cancellation-policy-modal-heading {
    margin: 0 0 1.5rem;
    font-size: 1rem; }
    .Cancellation-policy-modal-heading-amenities {
      font-size: 0.85rem; }

.Cancellation-policy-wrapper .Modal-window {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14); }
  @media (min-width: 620px) {
    .Cancellation-policy-wrapper .Modal-window {
      width: 400px; } }
  @media (min-width: 820px) {
    .Cancellation-policy-wrapper .Modal-window {
      width: 600px; } }
  .Cancellation-policy-wrapper .Modal-window-header {
    padding: 0;
    position: relative; }
    .Cancellation-policy-wrapper .Modal-window-header-icon-close {
      position: absolute;
      right: 1rem;
      top: 1rem; }

.border-top-midlightgrey {
  border-top: 1px solid #efefef; }

.border-top-lightgrey {
  border-top: 1px solid #d3d1d1; }

.property-png-PH {
  background-image: url("../img/drafts/property.png");
  height: 216px; }

.font-small {
  font-size: 0.875rem; }

.addOns {
  padding-left: 0.5rem;
  padding-top: 0.5rem; }

.modal-footer {
  background-color: #f9f9f9;
  border-top: 1px solid #767676;
  border-radius: 0 0 8px 8px;
  padding: 1rem;
  display: flex;
  justify-content: space-between; }

.modal-ctrl {
  cursor: pointer;
  font-weight: bolder; }
  .modal-ctrl.clear {
    color: #272727; }
  .modal-ctrl.apply {
    color: #3e93ba; }

.tooltip-container {
  position: relative;
  display: inline-block; }

.tooltip-container .tooltip {
  visibility: hidden;
  width: 300px;
  background-color: #454545;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: .5rem;
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: 50%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.875rem; }

.tooltip-container .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: #454545 transparent transparent transparent; }

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1; }

.small {
  padding: 0 1rem 1rem; }
  @media (min-width: 980px) {
    .small {
      display: none; } }
  .small h1 {
    font-size: 1.75rem; }
  .small .GlobalCustomize-small-form {
    padding-top: 1.5rem; }
    .small .GlobalCustomize-small-form .input-block {
      margin: 0;
      padding-top: 1rem;
      padding-bottom: 1rem; }
  .small .GlobalCustomize-info-apartments-container {
    flex-direction: column; }
  .small .GlobalCustomize-info-type {
    display: flex; }
    .small .GlobalCustomize-info-type .custom-radio-icon {
      margin-right: .75rem; }
    .small .GlobalCustomize-info-type__price {
      margin-top: 0;
      margin-right: .75rem; }
    .small .GlobalCustomize-info-type__type {
      margin-top: 0.1rem; }
  .small .GlobalCustomize-rates {
    background: #f9f9f9;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    border: 1px solid #d3d1d1;
    padding: 1rem; }
    .small .GlobalCustomize-rates .input-block {
      margin: 1rem 0 0; }
      .small .GlobalCustomize-rates .input-block-title {
        font-weight: 900;
        margin-bottom: .5rem;
        font-size: .75rem; }
  .small .customize-calendar-cover {
    display: none;
    position: absolute;
    left: 0;
    bottom: auto;
    z-index: 10;
    border-radius: 0 0 8px 8px !important;
    background-color: white;
    overflow: hidden;
    width: 100%;
    top: 82%; }
  .small .visualController:checked ~ .customize-calendar-cover {
    display: block; }
  .small .visualController:checked ~ .input-block-label .label-xs-container__item {
    flex: 0 0 50%; }
    .small .visualController:checked ~ .input-block-label .label-xs-container__item:first-child {
      border-radius: 8px 0 0 0;
      border-right: 0; }
    .small .visualController:checked ~ .input-block-label .label-xs-container__item:last-child {
      border-radius: 0 8px 0 0; }

@media (max-width: 979px) {
  .large {
    display: none; } }

.addOns {
  padding-left: 0.5rem;
  padding-top: 0.5rem; }

.modal-footer {
  background-color: #f9f9f9;
  border-top: 1px solid #767676;
  border-radius: 0 0 8px 8px;
  padding: 1rem;
  display: flex;
  justify-content: flex-end; }

.warning {
  color: red; }

body {
  font-family: 'Roboto', sans-serif !important;
  font-size: 15px;
  color: #4b4b4b; }

h2, h3 {
  margin: 0 0 20px 0;
  font-weight: bold; }

p {
  margin: 0 0 20px 0; }

.btn-warning {
  background-color: #f8752e; }

.wrap,
.container {
  position: relative; }

.tabs-ui .nav-tabs {
  display: none; }

.bg-cover {
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  background-image: url("../img/hero-bg.jpg"); }

.pointer {
  cursor: pointer; }

#brand .row {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px; }
  #brand .row .nomad-logo {
    height: 85px;
    width: auto;
    height: 60px; }

#hero .row {
  display: flex;
  align-items: center; }
  #hero .row .col {
    color: white;
    text-align: center;
    min-height: 160px;
    padding-top: 30px; }
    #hero .row .col h1 {
      font-weight: 300;
      margin: 0; }

#nav {
  background-color: #e9e9e9; }
  #nav .col ul {
    display: flex;
    align-items: center; }
    #nav .col ul li {
      display: inline-block;
      display: flex;
      justify-content: center;
      text-align: center;
      vertical-align: middle;
      max-width: 125px;
      min-height: 70px; }
      #nav .col ul li:first-child {
        width: 190px;
        max-width: 190px;
        display: table; }
      #nav .col ul li:nth-child(7) {
        max-width: 165px; }
      #nav .col ul li.active a {
        background-color: #8ac349;
        color: white; }
      #nav .col ul li a {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
        min-height: 70px;
        display: inline-block;
        display: flex;
        align-items: center;
        vertical-align: middle; }

.center-content {
  display: flex;
  align-items: center; }

#header .row {
  padding-top: 40px;
  padding-bottom: 0px; }
  #header .row h2 {
    text-transform: uppercase;
    color: #8ac349;
    font-weight: 400;
    font-size: 23px; }
  #header .row .icon-graphic {
    padding-top: 70px; }
    #header .row .icon-graphic #building-icon {
      height: 110px !important;
      width: auto !important; }

#footer {
  background-color: #414141; }
  #footer .row {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px; }
    #footer .row * {
      color: #727272;
      font-size: 12px; }
    #footer .row .social-icons {
      float: right; }
      #footer .row .social-icons .fa {
        margin: 15px;
        font-size: 1.5em; }

#body .row {
  padding-top: 50px;
  padding-bottom: 50px; }
  #body .row h2 {
    font-size: 24px;
    line-height: 26px; }
  #body .row h3 {
    font-size: 18px;
    line-height: 24px; }
  #body .row ul {
    margin: 0;
    padding: 0;
    margin-bottom: 10px; }
    #body .row ul li {
      display: block;
      margin: 5px 0;
      list-style: none;
      padding-left: 20px;
      position: relative; }
      #body .row ul li:before {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #8ac349;
        position: absolute;
        left: 0px; }

#body #rates ul li:before {
  content: "\f111";
  font-size: 8px;
  padding: 5px; }

.bg-pattern {
  background-image: url("../img/sidebar-square.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  min-height: 260px;
  text-align: center;
  padding: 45px 0;
  font-size: 20px;
  margin: 0 0 15px 0; }
  .bg-pattern .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px; }
    .bg-pattern .title:after {
      display: table;
      margin: 0 auto;
      margin-top: 10px;
      width: 70px;
      height: 4px;
      bottom: 0;
      content: ' ';
      background-color: #44b3e0; }
  .bg-pattern .text {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 23px; }
  .bg-pattern * {
    margin: 15px 0; }

.bg-color {
  margin: 15px 0;
  padding: 40px;
  background-color: #9fd9f1;
  width: 100%;
  min-height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .bg-color .btn, .bg-color .btn-light-blue, .bg-color .btn-empty, .bg-color .btn-empty-cta, .bg-color .btn-large, .bg-color .btn-empty-grey, .bg-color .btn-empty-red, .bg-color .btn-cta, .bg-color .btn-grey, .bg-color .btn-red, .bg-color .btn-empty-blue, .bg-color .btn-white, .bg-color .btn-action, .bg-color .btn-secondary {
    border-radius: 30px;
    text-transform: uppercase;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); }

uib-accordion .panel-open .panel-heading:before {
  content: "\f068"; }

uib-accordion .panel {
  border: 0 !important;
  box-shadow: 0 0 0 transparent !important; }

uib-accordion .panel-heading {
  background: none !important;
  border: 0 !important;
  position: relative;
  padding-left: 20px; }
  uib-accordion .panel-heading:before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f067";
    color: #8ac349;
    position: absolute;
    left: 0px !important; }

uib-accordion .panel-collapse {
  background: none !important;
  border: 0 !important; }
  uib-accordion .panel-collapse .panel-body {
    border-top: 0 !important; }

#gallery .bg-image {
  display: block;
  width: 100%;
  height: 325px;
  background-size: auto 325px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom; }

.carousel-caption {
  text-shadow: 0 0 5px black; }

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: transparent;
  text-align: center;
  text-shadow: 0 !important;
  background-color: transparent;
  filter: none !important;
  opacity: 0; }

.carousel-control.left {
  background-image: -none !important;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
  filter: none !important;
  background-repeat: repeat-x; }

.carousel-control.right {
  right: 0;
  left: auto;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
  filter: none !important;
  background-repeat: repeat-x; }

.carousel-control:hover,
.carousel-control:focus {
  color: transparent;
  text-decoration: none;
  filter: none !important;
  outline: 0;
  opacity: 0; }

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px; }

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  margin-left: -10px; }

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  margin-right: -10px; }

.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1; }

.carousel-control .icon-prev:before {
  content: ''; }

.carousel-control .icon-next:before {
  content: ''; }

#info-request .form-group {
  width: 46%;
  margin-right: 30px;
  float: left; }
  #info-request .form-group.textarea {
    width: 96%;
    clear: both;
    float: none; }
  #info-request .form-group:last-child {
    clear: both;
    display: block;
    float: none; }

#info-request .radios {
  clear: both;
  float: none; }

#state-control {
  margin-bottom: 50px;
  margin-top: 20px; }
  #state-control .form-label {
    padding-right: 22px; }
  #state-control .form-control {
    width: 200px; }

#rates-states .rates-city {
  margin-bottom: 50px;
  margin-top: 10px; }
  #rates-states .rates-city .rates-table {
    width: 100%; }
    #rates-states .rates-city .rates-table .table-headers {
      background-color: #d9d9d9;
      width: 100%;
      border-radius: 4px 4px 0 0;
      height: 45px; }
      #rates-states .rates-city .rates-table .table-headers:after, #rates-states .rates-city .rates-table .table-headers:before {
        clear: both;
        content: ' ';
        dispaly: table;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        float: none; }
      #rates-states .rates-city .rates-table .table-headers div {
        font-size: 14px;
        padding: 10px 0;
        text-align: center; }
    #rates-states .rates-city .rates-table .table-body {
      border: 1px solid #d9d9d9;
      border-width: 0 1px 1px 1px;
      border-radius: 0 0 4px 4px;
      min-height: 37px; }
      #rates-states .rates-city .rates-table .table-body:after {
        content: ' ';
        display: block;
        float: none;
        clear: both; }
      #rates-states .rates-city .rates-table .table-body div {
        font-size: 14px;
        padding: 10px 0;
        text-align: center; }
